import React from "react";

const ArrowUp = () => {
  return (
    <svg width="97" height="96" viewBox="0 0 97 96" fill="none">
      <path
        d="M40 51.4563L49.2827 43L57.739 52.2827"
        stroke="#091820"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.877 48C24.877 61.2548 35.6221 72 48.877 72C62.1318 72 72.877 61.2548 72.877 48C72.877 34.7452 62.1318 24 48.877 24C35.6221 24 24.877 34.7452 24.877 48Z"
        stroke="#091820"
        strokeWidth="3"
      />
    </svg>
  );
};

export default ArrowUp;
