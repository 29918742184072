import React from "react";

const MicroSoldering = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0H90C97.9554 0.0037099 105.584 3.16561 111.209 8.79089C116.834 14.4162 119.996 22.0446 120 30V90C119.996 97.9554 116.834 105.584 111.209 111.209C105.584 116.834 97.9554 119.996 90 120H30C22.0446 119.996 14.4162 116.834 8.79089 111.209C3.16561 105.584 0.0037099 97.9554 0 90V30C0.0037099 22.0446 3.16561 14.4162 8.79089 8.79089C14.4162 3.16561 22.0446 0.0037099 30 0Z"
        fill="#8EF4E8"
      />
      <path
        d="M61.7676 58.2299H63.6536C64.123 58.2299 64.5732 58.4164 64.9052 58.7484C65.2371 59.0803 65.4236 59.5305 65.4236 59.9999V62.7139H70.1746V56.0319H61.7676V58.2299Z"
        fill="black"
      />
      <path
        d="M65.4219 66.2529H70.1729V70.1769H65.4219V66.2529Z"
        fill="black"
      />
      <path
        d="M49.8223 61.7679H61.8863V70.1759H49.8223V61.7679Z"
        fill="black"
      />
      <path
        d="M49.8223 49.8219H58.2303V58.2299H49.8223V49.8219Z"
        fill="black"
      />
      <path
        d="M77.3566 39.2659H42.6426C41.7473 39.267 40.889 39.6231 40.2559 40.2562C39.6228 40.8893 39.2667 41.7476 39.2656 42.6429V77.3569C39.2667 78.2522 39.6228 79.1106 40.2559 79.7437C40.889 80.3767 41.7473 80.7329 42.6426 80.7339H77.3566C78.2519 80.7329 79.1103 80.3767 79.7434 79.7437C80.3764 79.1106 80.7326 78.2522 80.7336 77.3569V42.6429C80.7326 41.7476 80.3764 40.8893 79.7434 40.2562C79.1103 39.6231 78.2519 39.267 77.3566 39.2659ZM73.7166 71.9479C73.7166 72.4174 73.5301 72.8676 73.1982 73.1995C72.8663 73.5314 72.4161 73.7179 71.9466 73.7179H48.0466C47.5772 73.7179 47.127 73.5314 46.795 73.1995C46.4631 72.8676 46.2766 72.4174 46.2766 71.9479V48.0479C46.2766 47.5785 46.4631 47.1283 46.795 46.7963C47.127 46.4644 47.5772 46.2779 48.0466 46.2779H71.9466C72.4161 46.2779 72.8663 46.4644 73.1982 46.7963C73.5301 47.1283 73.7166 47.5785 73.7166 48.0479V71.9479Z"
        fill="black"
      />
      <path
        d="M61.7676 49.8219H70.1756V52.4919H61.7676V49.8219Z"
        fill="black"
      />
      <path
        d="M51.0839 31.7699C51.0729 31.3079 50.8817 30.8685 50.551 30.5456C50.2204 30.2227 49.7766 30.042 49.3144 30.042C48.8523 30.042 48.4085 30.2227 48.0778 30.5456C47.7472 30.8685 47.5559 31.3079 47.5449 31.7699V35.7259H51.0839V31.7699Z"
        fill="black"
      />
      <path
        d="M61.7704 31.7699C61.7595 31.3079 61.5682 30.8685 61.2376 30.5456C60.9069 30.2227 60.4631 30.042 60.0009 30.042C59.5388 30.042 59.095 30.2227 58.7643 30.5456C58.4337 30.8685 58.2424 31.3079 58.2314 31.7699V35.7259H61.7704V31.7699Z"
        fill="black"
      />
      <path
        d="M72.455 31.7699C72.444 31.3079 72.2528 30.8685 71.9221 30.5456C71.5915 30.2227 71.1477 30.042 70.6855 30.042C70.2234 30.042 69.7796 30.2227 69.4489 30.5456C69.1183 30.8685 68.927 31.3079 68.916 31.7699V35.7259H72.455V31.7699Z"
        fill="black"
      />
      <path
        d="M47.5449 88.2299C47.5559 88.6919 47.7472 89.1313 48.0778 89.4542C48.4085 89.7771 48.8523 89.9579 49.3144 89.9579C49.7766 89.9579 50.2204 89.7771 50.551 89.4542C50.8817 89.1313 51.0729 88.6919 51.0839 88.2299V84.2739H47.5449V88.2299Z"
        fill="black"
      />
      <path
        d="M58.2324 88.2299C58.2434 88.6919 58.4347 89.1313 58.7653 89.4542C59.096 89.7771 59.5398 89.9579 60.0019 89.9579C60.4641 89.9579 60.9079 89.7771 61.2385 89.4542C61.5692 89.1313 61.7604 88.6919 61.7714 88.2299V84.2739H58.2324V88.2299Z"
        fill="black"
      />
      <path
        d="M68.916 88.2299C68.927 88.6919 69.1183 89.1313 69.4489 89.4542C69.7796 89.7771 70.2234 89.9579 70.6855 89.9579C71.1477 89.9579 71.5915 89.7771 71.9221 89.4542C72.2528 89.1313 72.444 88.6919 72.455 88.2299V84.2739H68.916V88.2299Z"
        fill="black"
      />
      <path
        d="M90.0004 48.1619C90.0004 47.6925 89.8139 47.2423 89.482 46.9103C89.1501 46.5784 88.6998 46.3919 88.2304 46.3919H84.2744V49.9319H88.2304C88.4629 49.932 88.6931 49.8864 88.9079 49.7975C89.1227 49.7086 89.3179 49.5782 89.4823 49.4138C89.6467 49.2494 89.7771 49.0542 89.866 48.8394C89.9549 48.6246 90.0006 48.3944 90.0004 48.1619Z"
        fill="black"
      />
      <path
        d="M88.2304 57.0779H84.2744V60.6179H88.2304C88.6998 60.6179 89.1501 60.4314 89.482 60.0995C89.8139 59.7676 90.0004 59.3173 90.0004 58.8479C90.0004 58.3785 89.8139 57.9283 89.482 57.5963C89.1501 57.2644 88.6998 57.0779 88.2304 57.0779Z"
        fill="black"
      />
      <path
        d="M88.2304 67.7629H84.2744V71.3029H88.2304C88.6998 71.3029 89.1501 71.1164 89.482 70.7845C89.8139 70.4526 90.0004 70.0023 90.0004 69.5329C90.0004 69.0635 89.8139 68.6133 89.482 68.2813C89.1501 67.9494 88.6998 67.7629 88.2304 67.7629Z"
        fill="black"
      />
      <path
        d="M30 48.1619C29.9999 48.3944 30.0456 48.6246 30.1345 48.8394C30.2234 49.0542 30.3537 49.2494 30.5181 49.4138C30.6825 49.5782 30.8777 49.7086 31.0925 49.7975C31.3073 49.8864 31.5375 49.932 31.77 49.9319H35.726V46.3919H31.77C31.3006 46.3919 30.8504 46.5784 30.5184 46.9103C30.1865 47.2423 30 47.6925 30 48.1619Z"
        fill="black"
      />
      <path
        d="M30 58.8479C29.9999 59.0804 30.0456 59.3106 30.1345 59.5254C30.2234 59.7402 30.3537 59.9354 30.5181 60.0998C30.6825 60.2642 30.8777 60.3946 31.0925 60.4835C31.3073 60.5724 31.5375 60.6181 31.77 60.6179H35.726V57.0779H31.77C31.3006 57.0779 30.8504 57.2644 30.5184 57.5963C30.1865 57.9283 30 58.3785 30 58.8479Z"
        fill="black"
      />
      <path
        d="M30 69.5329C30 70.0023 30.1865 70.4526 30.5184 70.7845C30.8504 71.1164 31.3006 71.3029 31.77 71.3029H35.726V67.7629H31.77C31.3006 67.7629 30.8504 67.9494 30.5184 68.2813C30.1865 68.6133 30 69.0635 30 69.5329V69.5329Z"
        fill="black"
      />
    </svg>
  );
};

export default MicroSoldering;
