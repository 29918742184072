import React from "react";

export const CallUs = () => {
  return (
    <svg width="102" height="118" viewBox="0 0 102 118" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4249 109.464H60.6439C61.2034 109.464 61.74 109.687 62.1356 110.082C62.5313 110.478 62.7537 111.014 62.754 111.574V115.793C62.7537 116.352 62.5313 116.889 62.1356 117.285C61.74 117.68 61.2034 117.903 60.6439 117.903H56.4249C55.8654 117.903 55.3289 117.68 54.9332 117.285C54.5376 116.889 54.3152 116.352 54.3149 115.793V111.574C54.3152 111.014 54.5376 110.478 54.9332 110.082C55.3289 109.687 55.8654 109.464 56.4249 109.464Z"
        fill="#33A3FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.4249 109.464H74.6439C75.2034 109.464 75.74 109.687 76.1356 110.082C76.5313 110.478 76.7537 111.014 76.754 111.574V115.793C76.7537 116.352 76.5313 116.889 76.1356 117.285C75.74 117.68 75.2034 117.903 74.6439 117.903H70.4249C69.8654 117.903 69.3289 117.68 68.9332 117.285C68.5376 116.889 68.3152 116.352 68.3149 115.793V111.574C68.3152 111.014 68.5376 110.478 68.9332 110.082C69.3289 109.687 69.8654 109.464 70.4249 109.464Z"
        fill="#33A3FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.4249 109.464H88.6439C89.2034 109.464 89.74 109.687 90.1356 110.082C90.5313 110.478 90.7537 111.014 90.754 111.574V115.793C90.7537 116.352 90.5313 116.889 90.1356 117.285C89.74 117.68 89.2034 117.903 88.6439 117.903H84.4249C83.8654 117.903 83.3289 117.68 82.9332 117.285C82.5376 116.889 82.3152 116.352 82.3149 115.793V111.574C82.3152 111.014 82.5376 110.478 82.9332 110.082C83.3289 109.687 83.8654 109.464 84.4249 109.464Z"
        fill="#33A3FF"
      />
      <path
        d="M62.56 20.522C67.1434 21.4161 71.3557 23.6576 74.6578 26.9595C77.9599 30.2615 80.2016 34.4736 81.096 39.057L62.56 20.522ZM62.56 1.75195C72.0824 2.81 80.962 7.0744 87.7409 13.845C94.5199 20.6156 98.7952 29.4899 99.865 39.011L62.56 1.75195ZM95.173 76.452V90.534C95.1783 91.8409 94.9106 93.1345 94.387 94.3319C93.8635 95.5294 93.0956 96.6043 92.1325 97.4877C91.1695 98.3712 90.0325 99.0439 88.7945 99.4625C87.5565 99.8812 86.2446 100.037 84.943 99.919C70.5021 98.3505 56.6305 93.4163 44.443 85.513C33.1065 78.308 23.4953 68.6955 16.292 57.358C8.36159 45.1162 3.42632 31.1785 1.88602 16.674C1.76871 15.3763 1.92288 14.0684 2.33873 12.8336C2.75457 11.5988 3.42298 10.4642 4.30139 9.50183C5.1798 8.53951 6.24897 7.77064 7.44082 7.24418C8.63266 6.71771 9.92108 6.44519 11.224 6.44395H25.302C27.5793 6.42154 29.787 7.22795 31.5137 8.71289C33.2404 10.1978 34.3683 12.26 34.687 14.515C35.2809 19.0204 36.3826 23.4442 37.971 27.702C38.6021 29.3814 38.7385 31.2065 38.3642 32.9611C37.9899 34.7157 37.1205 36.3263 35.859 37.602L29.9 43.562C36.5801 55.3098 46.3071 65.0369 58.055 71.7169L64.014 65.757C65.2897 64.4955 66.9003 63.6261 68.6549 63.2518C70.4095 62.8775 72.2346 63.0139 73.914 63.645C78.1714 65.2337 82.5949 66.3357 87.1 66.93C89.3792 67.251 91.4608 68.3984 92.9493 70.154C94.4377 71.9096 95.2291 74.151 95.173 76.452V76.452Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CallUs;
