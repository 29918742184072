import React from "react";

const ComputerRepair = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0H90C97.9554 0.0037099 105.584 3.16561 111.209 8.79089C116.834 14.4162 119.996 22.0446 120 30V90C119.996 97.9554 116.834 105.584 111.209 111.209C105.584 116.834 97.9554 119.996 90 120H30C22.0446 119.996 14.4162 116.834 8.79089 111.209C3.16561 105.584 0.0037099 97.9554 0 90V30C0.0037099 22.0446 3.16561 14.4162 8.79089 8.79089C14.4162 3.16561 22.0446 0.0037099 30 0Z"
        fill="#FFE07B"
      />
      <path
        d="M88.237 83.427C90.1215 83.4201 91.9263 82.6658 93.2551 81.3296C94.584 79.9934 95.3285 78.1845 95.325 76.3L95.361 37.12C95.3544 35.233 94.6016 33.4252 93.2669 32.0913C91.9322 30.7573 90.124 30.0055 88.237 30H31.247C29.3593 30.0055 27.5505 30.7579 26.2157 32.0927C24.8809 33.4275 24.1285 35.2363 24.123 37.124V76.3C24.1285 78.1877 24.8809 79.9965 26.2157 81.3313C27.5505 82.6661 29.3593 83.4185 31.247 83.424H17C17.0055 85.3117 17.7579 87.1205 19.0927 88.4553C20.4275 89.7901 22.2363 90.5425 24.124 90.548H95.36C97.2477 90.5425 99.0565 89.7901 100.391 88.4553C101.726 87.1205 102.478 85.3117 102.484 83.424L88.237 83.427ZM31.248 37.127H88.237V76.3H31.247L31.248 37.127ZM59.742 86.989C59.0373 86.9898 58.3483 86.7816 57.762 86.3906C57.1757 85.9997 56.7185 85.4437 56.4483 84.7929C56.1781 84.1421 56.107 83.4258 56.244 82.7345C56.3809 82.0433 56.7199 81.4083 57.2179 80.9097C57.7159 80.4111 58.3505 80.0715 59.0416 79.9337C59.7327 79.796 60.4491 79.8663 61.1002 80.1358C61.7513 80.4052 62.3078 80.8618 62.6994 81.4476C63.091 82.0335 63.3 82.7223 63.3 83.427C63.2974 84.3702 62.9218 85.2741 62.2552 85.9414C61.5886 86.6087 60.6852 86.9853 59.742 86.989Z"
        fill="black"
      />
    </svg>
  );
};

export default ComputerRepair;
