import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Form/Button";
import ArrowRight from "../../components/Icons/ArrowRight";
import ArrowUp from "../../components/Icons/ArrowUp";
import ArrowDown from "../../components/Icons/ArrowDown";
import LiveChat from "../../components/Icons/LiveChat";
import CallUs from "../../components/Icons/CallUs";
import Instagram from "../../components/Icons/Instagram";
import Twitter from "../../components/Icons/Twitter";
import FaqToggle from "../../components/Icons/FaqToggle";

import ContactImg from "../../assets/images/contact-img.png";

class Support extends Component {
  state = {
    faqOpen: 0,
    faqMore: false,
    policyMore: false,
  };

  componentDidMount() {
    if (this.props && this.props.location.state) {
      if (this.props.location.state.hash === "faq") {
        if (window.innerWidth < 800) window.scrollTo(0, 3500);
        else window.scrollTo(0, 2000);
      } else if (this.props.location.state.hash === "contact") {
        window.scrollTo(0, 500);
      } else if (this.props.location.state.hash === "policy") {
        window.scrollTo(0, 2700);
      }
    }
  }

  handleOnFaqOpen(faqOpen) {
    this.setState({ faqOpen: this.state.faqOpen === faqOpen ? 0 : faqOpen });
  }

  handleOnFaqMoreToggle() {
    this.setState({ faqMore: !this.state.faqMore });
  }

  handleOnPolicyMoreToggle() {
    this.setState({ policyMore: !this.state.policyMore });
  }

  render() {
    const state = this.state;

    return (
      <div className="Support">
        <Helmet>
          <meta name="title" content=" Support, FAQs, T&Cs | Tinc Support" />
          <meta
            name="description"
            content="Contact us to for your repairs or if you need help troubleshooting the issue"
          />
          <meta
            property="og:title"
            content=" Support, FAQs, T&Cs | Tinc Support"
          />
          <meta
            property="og:url"
            content="https://support.tinc.com.ng/support"
          />
          <meta
            property="og:description"
            content="Contact us to for your repairs or if you need help troubleshooting the issue"
          />
          <link rel="canonical" href="https://support.tinc.com.ng/support" />
          <title>Support, FAQs, T&Cs | Tinc Support</title>
        </Helmet>
        <Header title="Contacts">
          <div className="Header__body__content">
            <h1>Support</h1>
            <p>Frequently Asked Questions, Terms and Conditions & Support</p>
          </div>
        </Header>
        <div className="Support__body">
          <section id="contact">
            <div className="Support__body__strip">
              <div className="Support__body__strip__gradient">
                <p>
                  To speak with a customer support agent for immediate support,
                  please call{" "}
                  <b>
                    <a href="tel:+2347040409881">+234 704 040 9881</a>
                  </b>{" "}
                  or{" "}
                  <b>
                    <a href="tel:+2348096957186">+234 809 695 7186</a>
                  </b>
                  .
                </p>
              </div>

              <div className="Support__body__strip__img">
                <img src={ContactImg} alt="Contact strip" />
              </div>
            </div>

            <div className="Support__body__contact">
              <h3>
                Contact us to arrange your repair, or if you need help
                troubleshooting your issue.
              </h3>
              <br />

              <div className="row">
                <div className="col-xl-6">
                  <div className="Support__body__contact__block">
                    <div className="Support__body__contact__card">
                      <div className="Support__body__contact__card__icon">
                        <LiveChat />
                      </div>

                      <div className="Support__body__contact__card__details">
                        <h5>WhatsApp Chat</h5>
                        <p>
                          Need an immediate answer? Use our live chat tool to
                          speak to a qualified Tinc Support expert who can help
                          troubleshoot or book repair appointments.
                          <br /> Reach us from: <br />
                          Mon - Wed (9am - 5pm)
                          <br />
                          Thurs (10am - 5pm)
                          <br /> Fri (9am - 5pm)
                        </p>
                        <br />
                        <Button type="Button">
                          <a
                            href="https://wa.me/2347040409881"
                            target="__blank"
                            rel="noopener noreferrer"
                          >
                            Chat with our experts now <ArrowRight />
                          </a>
                        </Button>
                      </div>
                    </div>

                    <div className="Support__body__contact__card">
                      <div className="Support__body__contact__card__icon">
                        <CallUs />
                      </div>

                      <div className="Support__body__contact__card__details">
                        <h5>Call Us</h5>
                        <p>
                          Talk directly to a member of our friendly support team
                          over the phone. We can offer advice on your repair and
                          even help arrange a booking for you.
                        </p>
                        <br />
                        <div className="Support__body__contact__card__details__btn__block">
                          <Button type="Button">
                            <a
                              href="tel:+2347040409881"
                              target="__blank"
                              rel="noopener noreferrer"
                            >
                              0704 040 9881
                            </a>
                          </Button>
                          <Button type="Button">
                            <a
                              href="tel:+2348096957186"
                              target="__blank"
                              rel="noopener noreferrer"
                            >
                              0809 695 7186
                            </a>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="Support__body__contact__block">
                    <div className="Support__body__contact__card Support__body__contact__card--third">
                      <div className="Support__body__contact__card__icon">
                        <Instagram />
                      </div>

                      <div className="Support__body__contact__card__details">
                        <h5>Instagram Messenger</h5>
                        <p>
                          Get help fast using Instagram Messenger on your phone,
                          tablet or computer.
                        </p>
                        <br />
                        <Button type="Button">
                          <a
                            href="https://www.instagram.com/tincsupport"
                            target="__blank"
                            rel="noopener noreferrer"
                          >
                            Send us an instagram message <ArrowRight />
                          </a>
                        </Button>
                      </div>
                    </div>

                    <div className="Support__body__contact__card">
                      <div className="Support__body__contact__card__icon">
                        <Twitter />
                      </div>

                      <div className="Support__body__contact__card__details">
                        <h5>Twitter</h5>
                        <p>
                          We offer dedicated Twitter Support, available 6 days a
                          week, to answer your questions. And don’t worry, you
                          can send private messages to us directly too.
                        </p>
                        <br />

                        <Button type="Button">
                          <a
                            href="https://twitter.com/tincsupport?"
                            target="__blank"
                            rel="noopener noreferrer"
                          >
                            Tweet Us <ArrowRight />
                          </a>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="faq">
            <div className="Support__body__faq">
              <h2>Frequently Asked Questions</h2>
              <br />

              <div
                className={`Support__body__faq__item ${
                  state.faqOpen === 1
                    ? "Support__body__faq__item--open"
                    : "Support__body__faq__item--closed"
                }`}
              >
                <div
                  className="Support__body__faq__item__header"
                  onClick={() => this.handleOnFaqOpen(1)}
                >
                  <span>
                    1. Do you offer any type of warranty on your repairs?
                  </span>
                  <FaqToggle isOpen={state.faqOpen === 1 ? true : false} />
                </div>
                <div className="Support__body__faq__item__body">
                  Replacement parts used in repairs are covered by a 30-day
                  warranty from time of installation. If a part is found to have
                  a manufacturers defect, kindly return within 30 days. However,
                  this warranty is void under the following conditions: the part
                  is damaged or modified, serial sticker information is removed
                  or tampered with, Water/liquid damage or physical damage.
                </div>
              </div>

              <div
                className={`Support__body__faq__item ${
                  state.faqOpen === 2
                    ? "Support__body__faq__item--open"
                    : "Support__body__faq__item--closed"
                }`}
              >
                <div
                  className="Support__body__faq__item__header"
                  onClick={() => this.handleOnFaqOpen(2)}
                >
                  <span>
                    2. I need my JBL speakers fixed and I don’t know if you guys
                    have any way to pick up from my house?
                  </span>
                  <FaqToggle isOpen={state.faqOpen === 2 ? true : false} />
                </div>
                <div className="Support__body__faq__item__body">
                  Yes, we have a pick up and delivery service option. We can
                  pick up from you and have it delivered to you after repair for
                  a charge.
                </div>
              </div>

              <div
                className={`Support__body__faq__item ${
                  state.faqOpen === 3
                    ? "Support__body__faq__item--open"
                    : "Support__body__faq__item--closed"
                }`}
              >
                <div
                  className="Support__body__faq__item__header"
                  onClick={() => this.handleOnFaqOpen(3)}
                >
                  <span>3. When Will My Device be Ready?</span>
                  <FaqToggle isOpen={state.faqOpen === 3 ? true : false} />
                </div>
                <div className="Support__body__faq__item__body">
                  It depends on the kind of repair, some repairs take longer
                  than others depending on the technicality involved, however a
                  simple replacement of parts such as battery, keyboard or
                  screens usually takes 3-4hrs. Please note that same day
                  repairs are carried out on devices that arrive at our service
                  center on or before 1pm, devices that arrive after this time
                  have a lesser chance of been repaired same day.
                </div>
              </div>

              <div
                className={`Support__body__faq__item ${
                  state.faqOpen === 4
                    ? "Support__body__faq__item--open"
                    : "Support__body__faq__item--closed"
                }`}
              >
                <div
                  className="Support__body__faq__item__header"
                  onClick={() => this.handleOnFaqOpen(4)}
                >
                  <span>4. Will I lose My Files?</span>
                  <FaqToggle isOpen={state.faqOpen === 4 ? true : false} />
                </div>
                <div className="Support__body__faq__item__body">
                  We strongly suggest you back up your device from time to time,
                  either on the cloud or through external storage drives, some
                  repairs may reset your device and an option for recovery of
                  your data may become impossible, we offer Advanced recovery
                  option in some cases which have a 50/50 chance of recovery.
                </div>
              </div>

              <div
                className={`Support__body__faq__item ${
                  state.faqOpen === 5
                    ? "Support__body__faq__item--open"
                    : "Support__body__faq__item--closed"
                }`}
              >
                <div
                  className="Support__body__faq__item__header"
                  onClick={() => this.handleOnFaqOpen(5)}
                >
                  <span>
                    5. How do I check for my model number or serial number?
                  </span>
                  <FaqToggle isOpen={state.faqOpen === 5 ? true : false} />
                </div>
                <div className="Support__body__faq__item__body">
                  There are various ways of checking your device serial number
                  or model number. A quick way for mobile phones that is
                  powering on, is to dial *#06#, another method is to go to your
                  device settings and check. For devices that are not powering
                  on you could check the sim tray holder for an IMEI number or
                  the back of your device.
                </div>
              </div>

              {state.faqMore && (
                <div className="Support__body__faq__more__items">
                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 6
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(6)}
                    >
                      <span>
                        6. What should I do when my device falls in water or any
                        liquid?
                      </span>
                      <FaqToggle isOpen={state.faqOpen === 6 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      Do not attempt to switch it on as this can cause a serious
                      short circuit. Next, send the device to us for water
                      damage servicing.
                    </div>
                  </div>

                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 7
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(7)}
                    >
                      <span>7. Do you need my passcode?</span>
                      <FaqToggle isOpen={state.faqOpen === 7 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      Yes we do. If there is a password it hinders our ability
                      to test the functionality of the device. Please provide
                      your password with the device when sending it in for
                      repair.
                    </div>
                  </div>

                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 8
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(8)}
                    >
                      <span>
                        8. What if you are unable to save my water damaged
                        device?
                      </span>
                      <FaqToggle isOpen={state.faqOpen === 8 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      If we are unable to repair your water damaged device for
                      any reason, your payments will be refunded minus the cost
                      of pick up and delivery.
                    </div>
                  </div>

                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 9
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(9)}
                    >
                      <span>
                        9. Why is my device slower after updating to the newest
                        version of the software?
                      </span>
                      <FaqToggle isOpen={state.faqOpen === 9 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      Older devices have been known to slow down if a recent
                      software update has been installed because the device's
                      hardware is not equipped to support the new features of
                      the operating software.
                    </div>
                  </div>
                </div>
              )}

              <div className="Support__body__faq__more">
                <Button
                  type="Button"
                  onClick={() => this.handleOnFaqMoreToggle()}
                >
                  {state.faqMore ? "Show Less" : "Show More"}
                  {state.faqMore ? <ArrowUp /> : <ArrowDown />}
                </Button>
              </div>
            </div>
          </section>

          <section id="policy">
            <div className="Support__body__policy">
              <h2>Terms &amp; Conditions</h2>
              <br />

              <p>
                This website is operated by Tinc Support and throughout this
                website, the terms “Tinc”,“we”, “us” and “our” refer to Tinc
                Support. We offer this website, including all the information,
                products, tools, and services available to you, the user. By
                visiting our site and/or purchasing anything from us, you engage
                in our “Service” and agree to be bound by all terms, conditions,
                policies, and notices stated here. These terms of Service apply
                to all users of the site, including without limitation, users
                who are browsers, vendors, customers, merchants, and/or
                contributors of content.
              </p>

              {state.policyMore && (
                <p>
                  <br />
                  <br />
                  <h3>SECTION 1 – WARRANTY &amp; RETURNS</h3>
                  <br />
                  <h5>Accessories</h5>
                  <p>
                    Warranty on accessories is subject to the original
                    manufacturer&#39;s warranty policy.
                  </p>
                  <br />
                  <h5>
                    Tinc Support Responsibilities &amp; Limited Warranty:
                  </h5>
                  <p>
                    Tinc Support may use rebuilt, reconditioned, or new parts
                    or components when repairing Your Device. Repaired/replaced
                    cases, pouches and holsters will be warranted for a period
                    of thirty (30) days. If Your Device should malfunction, it
                    must be returned to the Tinc service centre for evaluation,
                    and all costs of shipping will be borne solely by the
                    purchaser. Tinc Support customer service department will
                    diagnose the repair upon receipt. Upon examination by Tinc
                    Support, if Your Device is found to be defective it will be
                    repaired or replaced at no charge. This warranty does not
                    apply to defects resulting from any action by you, including
                    but not limited to mishandling, physical damage, water
                    damage, improper interfacing, operation outside of design
                    limits, repair by someone other than Tinc, use of any other
                    product other than Tinc Support products, or unauthorized
                    modification. This warranty is VOID if Your Device shows
                    evidence of having been tampered with (broken seal) or shows
                    evidence of being damaged as a result of excessive
                    corrosion; or current, heat, moisture or vibration; improper
                    specification; misapplication; misuse; abuse or other
                    operating conditions outside of Tinc’ control. Software
                    warranty applies to factory restores, backups, jailbreaks,
                    unlocks, and applies to computers, laptops, cellular phones,
                    etc. The warranty is VOID if customer performs any software
                    modifications not limited to restore, updating software on
                    the device, downloading unauthorized or unapproved software,
                    viruses, malware, spyware, or attempts to modify any
                    software that has been installed by Tinc Support. Tinc
                    Support employees reserve the right to VOID its warranty if
                    any improper software installations or modifications have
                    been made after customer has left the store location. Unless
                    otherwise required by law, all replaced devices, parts,
                    components, boards and equipment will become the property of
                    Tinc Support, and you waive all rights you may have under
                    state or other laws to the replaced device or repaired or
                    replaced parts. Tinc support will be responsible to the
                    extent provided under applicable law for the physical
                    safekeeping of Your Device while in Tinc Support’s
                    possession for Service. If Your Device is lost or damaged
                    while it is in Tinc Support’s possession for Service, Tinc
                    Support will repair Your Device or replace it with an
                    equivalent device, which at Tinc Support’s option may be
                    new or refurbished. If you fail to pick up your device or
                    otherwise arrange for its return after repeated efforts to
                    contact you, after thirty (30) days following the completion
                    of repairs Tinc Support will treat the device as abandoned,
                    and may dispose of the property in its sole discretion in
                    accordance with applicable provisions of law, including sale
                    to recoup administrative and repair costs.
                  </p>
                  <br />
                  Also, please take note of the following:
                  <br />
                  <ol>
                    <li>
                      For smartphone repairs, always remove your simcard and
                      memory card. Before dropping it off or scheduling a
                      pick-up and delivery service
                    </li>
                    <li>
                      Tinc is not responsible for any data loss. (Always backup
                      your files)
                    </li>
                    <li>
                      Tinc will NOT be responsible for any further damage that
                      may result from any diagnosis or repair attempt
                    </li>
                    <li>
                      Water and liquid damage repairs may require additional
                      replacement parts that will be quoted to the customer at
                      time of liquid damage assessment.
                    </li>
                    <li>
                      Devices left unclaimed after 30 days from date of drop off
                      will be discarded. This includes partial or full repairs.
                    </li>
                    <li>
                      Initial working condition for devices registered as “not
                      coming on” cannot be determined until the power problem
                      has been resolved and as such, Tinc would not be
                      responsible for any underlying issue that may be
                      discovered after the resolution of the “power” issue
                    </li>
                  </ol>
                  <br />
                  Replacement parts used in repairs are covered by a 30-day
                  warranty. From time of installation. If a part is found to
                  have a manufacturers defect, kindly return within 30 days.
                  However, this warranty is void under the following conditions:
                  <br />
                  <ol>
                    <li>The part is damaged or modified.</li>
                    <li>
                      Serial sticker information is removed or tampered with
                    </li>
                    <li>Water/liquid damage or physical damage</li>
                  </ol>
                  <b>N.B.</b> - Whether a warranty is applicable or not after
                  seven days, is at the discretion of Tinc.
                  <br />
                  <br />
                  <h3>SECTION 2 - PRODUCTS OR SERVICES</h3>
                  <ul>
                    <li>
                      Some of our products or services may be available
                      exclusively online via the website. These products or
                      services may be limited in quantity and are subject to
                      return or exchange only according to our Return Policy.
                    </li>
                    <li>
                      We’ve made great efforts to display, as accurately as
                      possible, the colors and images of the products that
                      appear in the store. We therefore cannot guarantee that
                      your computer monitor's display of any of the colors will
                      be accurate
                    </li>
                    <li>
                      We reserve the full right, but are not obligated, to limit
                      the sales of our products or Services to any person,
                      geographic region or jurisdiction and we may exercise this
                      right on a case-by-case basis.
                    </li>
                    <li>
                      We reserve the right to limit the quantities of any
                      products or services that we offer. All descriptions of
                      products or product pricing are subject to change at
                      anytime without notice, at our sole discretion.
                    </li>
                    <li>
                      We reserve the right to discontinue any product at any
                      time. Any offer for any product or service made on this
                      site is void where prohibited.
                    </li>
                    <li>
                      We do not warrant that the quality of any products,
                      services, information, or other material purchased or
                      obtained by you will meet your expectations, or that any
                      errors in the service will be corrected.
                    </li>
                  </ul>
                  <br />
                  <br />
                  <h3>SECTION 3 - MODIFICATIONS TO THE SERVICE AND PRICES</h3>
                  <ul>
                    <li>
                      Prices for our products are subject to change and can be
                      done without prior notice
                    </li>
                    <li>
                      Tinc reserves the full right to modify or discontinue a
                      Service (or any part of the content thereof) without prior
                      notice.
                    </li>
                    <li>
                      We shall not be liable to you or to any third-party for
                      any modification, price change, suspension or
                      discontinuance of any of our services
                    </li>
                  </ul>
                  <br />
                  <br />
                  <h3>SECTION 4 – PAYMENT</h3>
                  <ul>
                    <li>
                      All clients are to pay minimum 70% (of the total cost of
                      repair) before any repair job is taken in, and 30% on
                      completion of the Job.
                    </li>
                    <li>
                      For pick up and delivery services, all clients are to pay
                      minimum 70% (of the total cost of repair) + pick up charge
                      before any repair job is taken in, and 30% on completion
                      of the Job.
                    </li>
                    <li>
                      If repair isn’t successful, the 70% initial down payment
                      would be refunded to the client excluding the pickup
                      charge
                    </li>
                  </ul>
                </p>
              )}

              <div className="Support__body__faq__more">
                <Button
                  type="Button"
                  onClick={() => this.handleOnPolicyMoreToggle()}
                >
                  {state.policyMore ? "Show Less" : "Show More"}
                  {state.policyMore ? <ArrowUp /> : <ArrowDown />}
                </Button>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Support;
