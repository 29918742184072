//@flow
import React, { type Node } from "react";

type FaqToggleProps = {
  isOpen: Boolean,
};

const FaqToggle = (props: FaqToggleProps): Node => {
  return (
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none">
      <path
        d="M30 58C44.9117 58 57 45.6878 57 30.5C57 15.3122 44.9117 3 30 3C15.0883 3 3 15.3122 3 30.5C3 45.6878 15.0883 58 30 58Z"
        fill="white"
        stroke="#33A3FF"
        strokeWidth="5"
      />
      <path d="M14.967 30.4668H45.344" stroke="#33A3FF" strokeWidth="5" />
      {!props.isOpen && (
        <path
          d="M30.156 15.2778L30.156 45.6548"
          stroke="#33A3FF"
          strokeWidth="5"
        />
      )}
    </svg>
  );
};

FaqToggle.defaultProps = {
  isOpen: false,
};

export default FaqToggle;
