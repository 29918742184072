import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
/*
import Button from "../../components/Form/Button";
import ArrowDown from "../../components/Icons/ArrowDown";
*/

class Repair extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Repair">
        <Helmet>
          <meta
            name="title"
            content=" Tinc Support | Book an appointment to repair your device"
          />
          <meta
            name="description"
            content="From the classic unexpected phone drop to the laptop that stops working for no reason. we fix your favorite tech."
          />
          <meta
            property="og:title"
            content=" Tinc Support | Book an appointment to repair your device"
          />
          <meta
            property="og:url"
            content="https://support.tinc.com.ng/repair"
          />
          <meta
            property="og:description"
            content="From the classic unexpected phone drop to the laptop that stops working for no reason. we fix your favorite tech."
          />
          <link rel="canonical" href="https://support.tinc.com.ng/repair" />
          <title>
            {" "}
            Tinc Support | Book an appointment to repair your device
          </title>
        </Helmet>

        <Header title="Bookings">
          <div className="Header__body__content">
            <h1>Book An Appointment</h1>
            <p>
              From the classic unexpected phone drop to the laptop that stops
              working for no reason, we fix your favorite tech. Book an
              appointment now.
            </p>
          </div>
        </Header>
        <div class="iframe-repair">
          <iframe
            title="Repair iframe"
            src={
              "https://boddssupport.repairdesk.co/widget.php?r=site/repairwidget&token=5cf0cb556b0261559284565"
            }
            style={{
              width: "100%",
              marginTop: 200,
              scrolling: "no",
            }}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Repair;
