import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import Button from "../../../components/Form/Button";
import ArrowRight from "../../../components/Icons/ArrowRight";
import MenuIcon from "../../../components/Icons/Menu";

import TincLogo from "../../../assets/images/tinc-logo.png";
import HeaderImg from "../../../assets/images/header-img.jpg";
import IphoneX from "../../../assets/images/iPhone-X.png";
import AppleLogo from "../../../assets/images/apple-logo.png";
import SamsungLogo from "../../../assets/images/samsung-logo.png";
import DellLogo from "../../../assets/images/dell-logo.png";
import HpLogo from "../../../assets/images/hp-logo.png";
import HuaweiLogo from "../../../assets/images/huawei-logo.png";
import LgLogo from "../../../assets/images/lg-logo.png";
import NokiaLogo from "../../../assets/images/nokia-logo.png";
import OneplusLogo from "../../../assets/images/oneplus-logo.png";

class Header extends Component {
  state = {
    isMenuActive: false,
  };

  handleToggleMenu = (state) => {
    this.setState({ isMenuActive: state });
  };

  render() {
    const state = this.state;

    return (
      <div className="Header" id="Home__bg__size">
        <div className="Header__bg">
          <div className="Header__bg__img">
            <img src={HeaderImg} alt="HeaderImg" />
          </div>

          <div
            className={` ${
              state.isMenuActive
                ? "Header__top Header__top--dark"
                : "Header__top"
            }`}
          >
            <div className="Header__menu">
              <div className="Header__menu__inner">
                <div className="Header__menu__icon">
                  <MenuIcon
                    onClick={() => this.handleToggleMenu(!state.isMenuActive)}
                  />
                </div>

                <div className="Header__menu__logo">
                  <NavLink to="/">
                    <img src={TincLogo} alt="Tinc Logo" />
                  </NavLink>
                </div>
                <div className="Header__menu__links">
                  <NavLink
                    to="/repair"
                    className="Header__menu__link"
                    activeClassName="Header__menu__link--active"
                  >
                    Repair
                  </NavLink>
                  <NavLink
                    to="/track"
                    className="Header__menu__link"
                    activeClassName="Header__menu__link--active"
                  >
                    Track Repair
                  </NavLink>
                  <NavLink
                    to="/partnership"
                    className="Header__menu__link"
                    activeClassName="Header__menu__link--active"
                  >
                    Partnership
                  </NavLink>
                  <NavLink
                    to="/about"
                    className="Header__menu__link"
                    activeClassName="Header__menu__link--active"
                  >
                    About
                  </NavLink>
                  <div className="Header__menu__support">
                    <NavLink
                      to="/support"
                      className="Header__menu__link"
                      activeClassName="Header__menu__link--active"
                    >
                      Support <i className="fas fa-caret-down"></i>
                    </NavLink>

                    <div className="Header__menu__support__subMenu">
                      <NavLink
                        to={{
                          pathname: "/support",
                          hash: "#faq",
                          state: { hash: "faq" },
                        }}
                        className="Header__menu__support__link"
                        activeClassName="Header__menu__support__link--active"
                      >
                        FAQ's
                      </NavLink>

                      <NavLink
                        to={{
                          pathname: "/support",
                          hash: "#contact",
                          state: { hash: "contact" },
                        }}
                        className="Header__menu__support__link"
                        activeClassName="Header__menu__support__link--active"
                      >
                        Contact Us
                      </NavLink>

                      <NavLink
                        to={{
                          pathname: "/support",
                          hash: "#policy",
                          state: { hash: "policy" },
                        }}
                        className="Header__menu__support__link"
                        activeClassName="Header__menu__support__link--active"
                      >
                        Terms and Conditions
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="Header__apple">
                  <div className="Header__apple__logo">
                    <img src={AppleLogo} alt="Apple Logo" />
                  </div>
                  <div className="Header__apple__text">
                    Apple
                    <br />
                    Certified
                    <br />
                    Technicians
                  </div>
                </div>
              </div>
            </div>
          </div>

          {state.isMenuActive && (
            <div className="Header__smallMenu">
              <div className="Header__smallMenu__links">
                <NavLink
                  to="/repair"
                  className="Header__smallMenu__link"
                  activeClassName="Header__smallMenu__link--active"
                >
                  Repair
                </NavLink>
                <NavLink
                  to="/track"
                  className="Header__smallMenu__link"
                  activeClassName="Header__smallMenu__link--active"
                >
                  Track Repair
                </NavLink>
                <NavLink
                  to="/partnership"
                  className="Header__smallMenu__link"
                  activeClassName="Header__smallMenu__link--active"
                >
                  Partnership
                </NavLink>
                <NavLink
                  to="/about"
                  className="Header__smallMenu__link"
                  activeClassName="Header__smallMenu__link--active"
                >
                  About
                </NavLink>
                <NavLink
                  to="/support"
                  className="Header__smallMenu__link"
                  activeClassName="Header__smallMenu__link--active"
                >
                  Support
                </NavLink>
              </div>
            </div>
          )}

          <div className="Header__body">
            <div className="Home__header">
              <div className="Home__header__content">
                <h1>Repair Your Broken Devices.</h1>
                <p>
                  At Tinc Support, we know how frustrating it can be when your
                  device gets damaged. Our specially trained technicians will
                  fix your devices
                </p>
                <br />
                <Button type="Button">
                  <NavLink to="/repair">
                    Start a Repair <ArrowRight />
                  </NavLink>{" "}
                </Button>
                <br />
                <div className="Home__header__logos">
                  <img src={AppleLogo} alt="Apple Logo" />
                  <img src={SamsungLogo} alt="Samsung Logo" />
                  <img src={HuaweiLogo} alt="Huawei Logo" />
                  <img src={LgLogo} alt="Lg Logo" />
                  <div className="Home__header__logos--remove">
                    <img src={NokiaLogo} alt="Nokia Logo" />
                    <img src={OneplusLogo} alt="Oneplus Logo" />
                    <img src={HpLogo} alt="Hp Logo" />
                    <img src={DellLogo} alt="Dell Logo" />
                  </div>
                </div>
              </div>

              <div className="Home__header__iphone">
                <img src={IphoneX} alt="iphone x" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
