import React from "react";

export const Twitter = () => {
  return (
    <svg width="124" height="102" viewBox="0 0 124 102" fill="none">
      <path
        d="M122.271 2.04667C117.029 5.74453 111.225 8.57284 105.082 10.4227C101.785 6.63191 97.4034 3.94512 92.5297 2.72566C87.6561 1.50619 82.5255 1.81289 77.8318 3.60427C73.1381 5.39565 69.1078 8.58528 66.286 12.7418C63.4641 16.8983 61.9868 21.8212 62.0539 26.8447V32.3187C52.4336 32.5679 42.9009 30.4339 34.3049 26.107C25.7089 21.78 18.3165 15.3944 12.7859 7.51867C12.7859 7.51867 -9.11209 56.7897 40.1569 78.6867C28.8827 86.3393 15.4523 90.1764 1.83691 89.6347C51.1049 117.006 111.322 89.6347 111.322 26.6807C111.317 25.1557 111.17 23.6345 110.884 22.1367C116.471 16.627 120.414 9.67061 122.271 2.04667Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.5 24C92.9853 24 95 21.9853 95 19.5C95 17.0147 92.9853 15 90.5 15C88.0147 15 86 17.0147 86 19.5C86 21.9853 88.0147 24 90.5 24Z"
        fill="#33A3FF"
      />
    </svg>
  );
};

export default Twitter;
