import React, { Component } from "react";
import { Helmet } from "react-helmet";

import AboutTinc from '../../assets/images/about-tinc.png'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomerLeft from "../../components/Icons/CustomerLeft";
import CustomerRight from "../../components/Icons/CustomerRight";

class About extends Component {
  scroller;
  state = {
    customerCursor: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (window.innerWidth > 768) this.setState({ customerCursor: 400 });
    this.handleOnScroll();
  }

  componentWillUnmount() {
    clearInterval(this.scroller);
  }

  handleOnScroll = () => {
    clearInterval(this.scroller);
    this.scroller = setInterval(() => {
      const customerCursor = this.state.customerCursor;
      if (customerCursor === -1500 && window.innerWidth > 768)
        this.setState({ customerCursor: 400 });
      else if (customerCursor === -1500 && window.innerWidth <= 768)
        this.setState({ customerCursor: 0 });
      else {
        this.setState({ customerCursor: customerCursor - 100 });
      }
    }, 3500);
  };

  handleOnPrevCursor = () => {
    clearInterval(this.scroller);
    const customerCursor = this.state.customerCursor;
    // if (window.innerWidth > 768) {
    //   if (customerCursor === 0) this.setState({ customerCursor: 400 });
    //   else if (customerCursor < 0)
    //     this.setState({ customerCursor: customerCursor + 500 });
    // } else {
    //   if (customerCursor === -550) this.setState({ customerCursor: 0 });
    //   else if (customerCursor < -550)
    //     this.setState({ customerCursor: customerCursor + 500 });
    // }

    if (window.innerWidth > 768) {
      if (customerCursor < 400)
        this.setState({ customerCursor: customerCursor + 100 });
    } else {
      if (customerCursor < 0)
        this.setState({ customerCursor: customerCursor + 100 });
    }

    setTimeout(() => {
      this.handleOnScroll();
    }, 6000);
  };

  handleOnNextCursor = () => {
    clearInterval(this.scroller);
    const customerCursor = this.state.customerCursor;
    // if (window.innerWidth > 768) {
    //   if (customerCursor === 400) this.setState({ customerCursor: 0 });
    //   else if (customerCursor <= 0)
    //     this.setState({ customerCursor: customerCursor - 500 });
    // } else {
    //   if (customerCursor === 0) this.setState({ customerCursor: -550 });
    //   else if (customerCursor <= -550)
    //     this.setState({ customerCursor: customerCursor - 500 });
    // }

    if (customerCursor > -1500)
      this.setState({ customerCursor: customerCursor - 100 });

    setTimeout(() => {
      this.handleOnScroll();
    }, 6000);
  };

  render() {
    const customerCursor = this.state.customerCursor;

    return (
      <div className="About">
        <Helmet>
          <meta name="title" content=" Tinc Support | About Us" />
          <meta
            name="description"
            content="We specialise in the repair of smartphones, tablets, computers, speakers and wearables"
          />
          <meta property="og:title" content=" Tinc Support | About Us" />
          <meta
            property="og:url"
            content="https://support.tinc.com.ng/about"
          />
          <meta
            property="og:description"
            content="We specialise in the repair of smartphones, tablets, computers, speakers and wearables"
          />
          <link rel="canonical" href="https://support.tinc.com.ng/about" />
          <title>Tinc Support | About Us</title>
        </Helmet>
        <Header title="About Us" isBottom={false}>
          <div className="Header__body__content">
            <h1>About Us</h1>
            <p>Our Repairs</p>
          </div>
        </Header>
        <div className="About__body">
          <div className="About__body__info">
            <div className="About__body__content">
              <h5>About Us</h5>
              <br />
              <h3>What We Do Best</h3>
              <p>
                Tinc Support is a technology company that helps restore your
                connection to the outside world. We fix electronic devices. From
                the classic unexpected phone drop to the laptop that stops
                working for ‘no’ reason. We’ve seen it all. We understand how
                traumatic it is when you break your favourite devices and we
                want you to feel better the moment you come in contact with us.
              </p>
            </div>

            {/* <div className="About__body__image">
              <div className="About__body__image__item"></div>
              <div className="About__body__image__batch">
                <div className="About__body__image__item"></div>
                <div className="About__body__image__item"></div>
              </div>
            </div> */}

            <div className="About__body__video">
              {/* <iframe
                src="https://www.youtube.com/embed/NbIxy92King"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="About__tinc"
              ></iframe> */}

              <img src={AboutTinc} alt="about tinc" />
            </div>
          </div>

          <div className="About__body__customers">
            <div className="About__body__customers__inner">
              <h3 className="About__body__customers__title">
                What Our Customers Have To Say
              </h3>
              <br />

              <div className="About__body__customers__items__overlay"></div>

              <ul
                className="About__body__customers__items"
                style={{ left: `${this.state.customerCursor}px` }}
              >
                <li className="About__body__customers__item">
                  <p>
                    I trust @tincsupport,
                    <br /> so i use them for my hardware and gadget needs.
                    <br />
                    And that's about that.
                  </p>
                  <br />
                  <h5>Benjamin Dada</h5>
                  <span>Google Pixel screen replacement</span>
                </li>

                <li className="About__body__customers__item">
                  <p>
                    I forgot to mention @tincsupport resolved my macbook hard
                    drive issues and now even the old dents that were on the avv
                    disapeared.
                    <br />
                    Una well done o.
                  </p>
                  <br />
                  <h5>Tomiwa Immanuel</h5>
                  <span>MacBook pro hard drive replacement</span>
                </li>

                <li className="About__body__customers__item">
                  <p>
                    Thank you so much, I love how professional you guys are. The
                    service was topnotch and you guys did an excellent job
                  </p>
                  <br />
                  <h5>Omoefe Agbontaen</h5>
                  <span>MacBook screen replacement</span>
                </li>

                <li className="About__body__customers__item">
                  <p>
                    Thanks for the great service. I am impressed by your
                    organization I must say. Big ups
                  </p>
                  <br />
                  <h5>Eleoramo Omobobola</h5>
                  <span>Hp pavilion screen replacement</span>
                </li>

                <li className="About__body__customers__item">
                  <p>I love your professionalism - great Job</p>
                  <br />
                  <h5>Funke Shobanjo</h5>
                  <span>Ipad 5 not coming on</span>
                </li>
              </ul>

              <div className="About__body__customers__controls">
                <div className="About__body__customers__controls__inner">
                  <CustomerLeft onClick={() => this.handleOnPrevCursor()} />
                  <div className="About__body__customers__controls__group">
                    <div
                      className={`About__body__customers__controls__item ${
                        customerCursor <= 400 &&
                        customerCursor >= 0 &&
                        "About__body__customers__controls__item--active"
                      } `}
                    ></div>
                    <div
                      className={`About__body__customers__controls__item ${
                        customerCursor < 0 &&
                        customerCursor >= -500 &&
                        "About__body__customers__controls__item--active"
                      }`}
                    ></div>
                    <div
                      className={`About__body__customers__controls__item ${
                        customerCursor < -500 &&
                        customerCursor >= -1000 &&
                        "About__body__customers__controls__item--active"
                      }`}
                    ></div>
                    <div
                      className={`About__body__customers__controls__item ${
                        customerCursor < -1000 &&
                        customerCursor >= -1500 &&
                        "About__body__customers__controls__item--active"
                      }`}
                    ></div>
                  </div>
                  <CustomerRight onClick={() => this.handleOnNextCursor()} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
