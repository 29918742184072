import React from "react";

const Package = () => {
  return (
    <svg width="109" height="108" viewBox="0 0 109 108" fill="none">
      <path
        d="M108.035 0.637939H32.165V66.1919C31.965 66.1869 31.772 66.1629 31.574 66.1629C27.1112 66.1549 22.7588 67.5496 19.132 70.1499L14.922 73.1579V69.6109H1.12695V107.546H14.922V103.26L21.775 98.3659C24.4041 96.4804 27.5596 95.4693 30.795 95.4759H64.68C67.1718 95.4826 69.6403 94.995 71.9425 94.0413C74.2446 93.0876 76.3348 91.6868 78.0919 89.9199L88.5 79.5119C88.8828 79.1226 89.2231 78.6935 89.515 78.2319H108.035V0.637939ZM104.587 14.4329H82.17V4.08694H104.587V14.4329ZM61.478 17.8809H78.722V27.7429L74.416 24.2939L70.107 27.7439L65.793 24.2949L61.479 27.7439L61.478 17.8809ZM61.478 14.4319V4.08694H78.722V14.4329L61.478 14.4319ZM58.027 4.08694V14.4329H35.614V4.08694H58.027ZM11.473 104.098H4.57295V73.0599H11.473V104.098ZM75.656 87.4819C74.218 88.9274 72.5078 90.0734 70.6241 90.8537C68.7405 91.634 66.7208 92.0331 64.682 92.0279H30.794C26.8394 92.0202 22.9825 93.2561 19.769 95.5609L14.922 99.0239V77.3949L21.136 72.9549C23.6529 71.1767 26.5816 70.0696 29.6454 69.7382C32.7093 69.4068 35.807 69.862 38.646 71.0609L43.289 73.0509C45.954 74.1981 48.8255 74.7879 51.727 74.7839H59.758C60.673 74.7839 61.5505 75.1474 62.1975 75.7944C62.8445 76.4414 63.208 77.3189 63.208 78.2339C63.208 79.1489 62.8445 80.0265 62.1975 80.6735C61.5505 81.3205 60.673 81.6839 59.758 81.6839H30.441V85.1329H59.754C61.5839 85.1329 63.339 84.406 64.633 83.112C65.927 81.818 66.654 80.0629 66.654 78.2329H84.905L75.656 87.4819ZM78.722 74.7819H65.692C65.2355 73.9969 64.6281 73.3099 63.9048 72.7607C63.1816 72.2115 62.3568 71.8109 61.478 71.5819V63.1979L65.784 66.6469L70.093 63.1969L74.407 66.6459L78.721 63.1969L78.722 74.7819ZM82.171 74.7819V59.2649H78.116L74.407 62.2309L70.093 58.7819L65.784 62.2289L62.084 59.2639H58.027V71.3379H51.727C49.2947 71.3408 46.8874 70.8467 44.653 69.8859L40.01 67.8959C38.5981 67.2935 37.1252 66.8455 35.617 66.5599V17.8809H58.027V31.6759H62.081L65.79 28.7099L70.107 32.1579L74.416 28.7109L78.116 31.6759H82.172V17.8809H104.587V74.7809L82.171 74.7819Z"
        fill="black"
        stroke="black"
        strokeWidth="0.7"
      />
      <path
        d="M73.5488 55.816H101.138V38.573H73.5488V55.816ZM76.9978 42.021H97.6898V52.367H76.9978V42.021Z"
        fill="#33A3FF"
      />
      <path
        d="M76.7939 41.851H97.9089V52.4169H76.7939V41.851Z"
        fill="#33A3FF"
      />
    </svg>
  );
};

export default Package;
