import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "./Header";
import Footer from "../../components/Footer";
import Button from "../../components/Form/Button";
import ArrowRight from "../../components/Icons/ArrowRight";
import DiagnosticDr from "../../components/Icons/DiagnosticDr";
import RepairMan from "../../components/Icons/RepairMan";
import Package from "../../components/Icons/Package";
import Warranty from "../../components/Icons/Warranty";
import Quality from "../../components/Icons/Quality";
import PhoneRepair from "../../components/Icons/PhoneRepair";
import ComputerRepair from "../../components/Icons/ComputerRepair";
import MicroSoldering from "../../components/Icons/MicroSoldering";
import SpeakerRepair from "../../components/Icons/SpeakerRepair";
import TabletRepair from "../../components/Icons/TabletRepair";
import OtherRepairs from "../../components/Icons/OtherRepairs";
import FaqToggle from "../../components/Icons/FaqToggle";

import AppleTechnicians from "../../assets/images/apple-technicians.png";
import QuickTurnaround from "../../assets/images/quick-turnaround.svg";
import Inlaks from "../../assets/images/inlaks.png";
import Cybersoc from "../../assets/images/cybersoc.png";
import Softcom from "../../assets/images/softcom.png";
import Uba from "../../assets/images/uba.png";
import MobilePhone from "../../assets/images/mobile-phone.png";

class Home extends Component {
  state = {
    faqOpen: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnFaqOpen(faqOpen) {
    this.setState({ faqOpen: this.state.faqOpen === faqOpen ? 0 : faqOpen });
  }

  render() {
    const state = this.state;

    return (
      <div className="Home">
        <Helmet>
          <meta
            name="title"
            content="Tinc Support | Professional Repair Service"
          />
          <meta
            name="description"
            content="Experience excellent repair service with warranty on every repair."
          />
          <meta
            property="og:title"
            content="Tinc Support | Professional Repair Service"
          />
          <meta property="og:url" content="https://support.tinc.com.ng" />
          <meta
            property="og:description"
            content="Experience excellent repair service with warranty on every repair."
          />
          <link rel="canonical" href="https://support.tinc.com.ng" />
          <title>Tinc Support | Professional Repair Service</title>
        </Helmet>
        <Header />
        <div className="Home__body">
          <div className="Home__body__whyus">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="Home__body__whyus__desc">
                  <h5>Why Us</h5>
                  <div className="Home__body__whyus__desc__divider" />
                  <p>
                    Experience Excellent Repair Service With Warranty on Every
                    Repair.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="Home__body__whyus__item">
                  <DiagnosticDr /> <br />
                  <h5>Free Diagnosis</h5>
                  <p>
                    We believe in a transparent and fair repair process. If
                    you’re not sure what’s wrong with your device, we’ll
                    diagnose it for free.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="Home__body__whyus__item">
                  <RepairMan /> <br />
                  <h5>Professional Repair Service</h5>
                  <p>
                    We have highly skilled technicians with over 10 years of
                    extensive experience in repairing tech and gadget devices.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="Home__body__whyus__item">
                  <Package /> <br />
                  <h5>Pickup &amp; Delivery Service</h5>
                  <p>
                    Tight schedules? No worries. We will pick up your device,
                    fix it and deliver to you, anywhere within Lagos.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="Home__body__whyus__item">
                  <Warranty /> <br />
                  <h5>Warranty On Every Repair</h5>
                  <p>
                    We want you to be confident that you’re getting the best
                    repair solution which is why we offer at least 30 Days
                    warranty on every repair giving you extra peace of mind.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="Home__body__whyus__item">
                  <Quality /> <br />
                  <h5>High Quality Repair Parts</h5>
                  <p>
                    One counterfeit part is enough to damage your device. We use
                    genuine and the highest quality replacement parts for all
                    our repairs.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="Home__body__services">
            <div className="Home__body__services__inner">
              <h5>Our Services</h5>
              <br />
              <h2>What can we fix for you today</h2>
              <br />
              <p>
                We understand your smartphone or device is precious to you.
                Tinc Support technicians are Apple Certified with years of
                experience and qualifications to repair all leading devices.
                With nearly 50,000 repairs to date, we are the trusted technical
                support centre.
              </p>

              <div className="Home__body__services__items">
                <div className="row">
                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <div className="Home__body__services__item Home__body__services__item--purple">
                      <Link to="/repair">
                        <PhoneRepair />
                        <h6>Smartphones</h6>
                        <span>Repair</span>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <div className="Home__body__services__item Home__body__services__item--red">
                      <Link to="/repair">
                        <TabletRepair />
                        <h6>Tablets</h6>
                        <span>Repair</span>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <div className="Home__body__services__item Home__body__services__item--yellow">
                      <Link to="/repair">
                        <ComputerRepair />
                        <h6>Computer</h6>
                        <span>Repair</span>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <div className="Home__body__services__item Home__body__services__item--green">
                      <Link to="/repair">
                        <MicroSoldering />
                        <h6>MicroSoldering</h6>
                        <span>Repair</span>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <div className="Home__body__services__item Home__body__services__item--neon-purple">
                      <Link to="/repair">
                        <SpeakerRepair />
                        <h6>Speaker</h6>
                        <span>Repair</span>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <div className="Home__body__services__item Home__body__services__item--blue">
                      <Link to="/repair">
                        <OtherRepairs />
                        <h6>Others</h6>
                        <span>Repair</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Home__body__svg-desc">
            <img src={AppleTechnicians} alt="Apple Technicians" />
          </div>

          <div className="Home__body__svg-desc">
            <Link
              to={{
                pathname: "/support",
                hash: "#contact",
                state: { hash: "contact" },
              }}
            >
              <img src={QuickTurnaround} alt="Quick Turnaround" />
            </Link>
          </div>

          <div className="Home__body__business">
            <h3>Tinc Support for Business</h3>
            <p>
              Damaged devices bring productivity to a standstill. Keep your
              employees well connected and your workforce running like a
              well-oiled machine by letting Tinc support focus on your
              organization’s tech needs so that you can focus on your core
              business. We already offer our comprehensive support service to
              several African-based businesses and would be glad to do the same
              for yours.
            </p>

            <div className="Home__body__business__btn">
              <Link to="/partnership">
                <Button>
                  {" "}
                  Learn More <ArrowRight />
                </Button>
              </Link>
            </div>

            <div className="Home__body__business__bar">
              <div className="Home__body__business__item">
                <img src={Inlaks} alt="Inlaks" />
              </div>

              <div className="Home__body__business__item">
                <img src={Cybersoc} alt="Cybersoc" />
              </div>

              <div className="Home__body__business__item">
                <img src={Softcom} alt="Softcom" />
              </div>

              <div className="Home__body__business__item">
                <img src={Uba} alt="Uba" />
              </div>
            </div>
          </div>

          <div className="Home__body__faq">
            <div className="Home__body__faq__inner">
              <h3>Frequently Asked Questions</h3>

              <div className="Home__body__faq__row">
                <div className="Home__body__faq__phone">
                  <img src={MobilePhone} alt="Mobile Phone" />
                </div>

                <div className="Home__body__faq__items">
                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 1
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(1)}
                    >
                      <span>
                        1. Do you offer any type of warranty on your repairs?
                      </span>
                      <FaqToggle isOpen={state.faqOpen === 1 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      Replacement parts used in repairs are covered by a 30-day
                      warranty from time of installation. If a part is found to
                      have a manufacturers defect, kindly return within 30 days.
                      However, this warranty is void under the following
                      conditions: the part is damaged or modified, serial
                      sticker information is removed or tampered with,
                      Water/liquid damage or physical damage.
                    </div>
                  </div>

                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 2
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(2)}
                    >
                      <span>2. Will I lose My Files?</span>
                      <FaqToggle isOpen={state.faqOpen === 2 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      We strongly suggest you back up your device from time to
                      time, either on the cloud or through external storage
                      drives, some repairs may reset your device and an option
                      for recovery of your data may become impossible, we offer
                      Advanced recovery option in some cases which have a 50/50
                      chance of recovery.
                    </div>
                  </div>

                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 3
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(3)}
                    >
                      <span>3. When Will My Device be Ready?</span>
                      <FaqToggle isOpen={state.faqOpen === 3 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      It depends on the kind of repair, some repairs take longer
                      than others depending on the technicality involved,
                      however a simple replacement of parts such as battery,
                      keyboard or screens usually takes 3-4hrs. Please note that
                      same day repairs are carried out on devices that arrive at
                      our service center on or before 1pm, devices that arrive
                      after this time have a lesser chance of been repaired same
                      day.
                    </div>
                  </div>

                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 4
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(4)}
                    >
                      <span>4. Do you need my passcode?</span>
                      <FaqToggle isOpen={state.faqOpen === 4 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      Yes we do. If there is a password it hinders our ability
                      to test the functionality of the device. Please provide
                      your password with the device when sending it in for
                      repair.
                    </div>
                  </div>

                  <div
                    className={`Support__body__faq__item ${
                      state.faqOpen === 5
                        ? "Support__body__faq__item--open"
                        : "Support__body__faq__item--closed"
                    }`}
                  >
                    <div
                      className="Support__body__faq__item__header"
                      onClick={() => this.handleOnFaqOpen(5)}
                    >
                      <span>
                        5. How do I check for my model number or serial number?
                      </span>
                      <FaqToggle isOpen={state.faqOpen === 5 ? true : false} />
                    </div>
                    <div className="Support__body__faq__item__body">
                      There are various ways of checking your device serial
                      number or model number. A quick way for mobile phones that
                      is powering on, is to dial *#06#, another method is to go
                      to your device settings and check. For devices that are
                      not powering on you could check the sim tray holder for an
                      IMEI number or the back of your device.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Home__body__metrics">
            <div className="Home__body__metrics__inner">
              <div className="Home__body__metrics__item">
                <small></small>
                <h1>7</h1>
                <h5>Years</h5>
              </div>

              <div className="Home__body__metrics__divider"></div>

              <div className="Home__body__metrics__item">
                <small>Over</small>
                <h1>10,000</h1>
                <h5>Gadgets Repaired</h5>
              </div>

              <div className="Home__body__metrics__divider"></div>

              <div className="Home__body__metrics__item">
                <small>More Than</small>
                <h1>5,000</h1>
                <h5>Satisfied Customers</h5>
              </div>

              <div className="Home__body__metrics__divider"></div>

              <div className="Home__body__metrics__item">
                <small>Over</small>
                <h1>3,000</h1>
                <h5>Clients</h5>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
