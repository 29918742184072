import React from "react";

export const Instagram = () => {
  return (
    <svg width="103" height="103" viewBox="0 0 103 103" fill="none">
      <path
        d="M26.563 2H76.063C79.3132 2 82.5316 2.64018 85.5344 3.88398C88.5372 5.12779 91.2656 6.95086 93.5639 9.24911C95.8621 11.5474 97.6852 14.2758 98.929 17.2786C100.173 20.2814 100.813 23.4998 100.813 26.75V76.25C100.813 79.5002 100.173 82.7186 98.929 85.7214C97.6852 88.7242 95.8621 91.4526 93.5639 93.7509C91.2656 96.0491 88.5372 97.8722 85.5344 99.116C82.5316 100.36 79.3132 101 76.063 101H26.563C19.9989 101 13.7036 98.3924 9.0621 93.7509C4.42057 89.1094 1.81299 82.8141 1.81299 76.25V26.75C1.81299 20.1859 4.42057 13.8906 9.0621 9.24911C13.7036 4.60758 19.9989 2 26.563 2V2Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.113 48.3806C71.7239 52.5002 71.0204 56.7077 69.1023 60.4044C67.1842 64.1011 64.1493 67.0989 60.4293 68.9713C56.7092 70.8438 52.4935 71.4955 48.3817 70.8339C44.2699 70.1723 40.4714 68.231 37.5265 65.2861C34.5816 62.3412 32.6403 58.5427 31.9786 54.4309C31.317 50.3191 31.9688 46.1033 33.8412 42.3833C35.7137 38.6633 38.7115 35.6284 42.4082 33.7103C46.1049 31.7922 50.3123 31.0886 54.432 31.6996C58.634 32.3228 62.5241 34.2809 65.5279 37.2847C68.5317 40.2884 70.4898 44.1786 71.113 48.3806V48.3806Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.75 17H84.25C84.9793 17.0003 85.6786 17.2901 86.1943 17.8057C86.7099 18.3214 86.9997 19.0207 87 19.75V25.25C86.9997 25.9793 86.7099 26.6786 86.1943 27.1943C85.6786 27.7099 84.9793 27.9997 84.25 28H78.75C78.0206 27.9997 77.3212 27.7099 76.8056 27.1941C76.2899 26.6783 76.0001 25.9789 76 25.2495V19.75C76.0003 19.0207 76.2901 18.3214 76.8057 17.8057C77.3214 17.2901 78.0207 17.0003 78.75 17Z"
        fill="#33A3FF"
      />
    </svg>
  );
};

export default Instagram;
