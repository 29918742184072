import React from "react";

const LiveChat = () => {
  return (
    <svg width="112" height="114" viewBox="0 0 112 114" fill="none">
      <path
        d="M87.152 15H11.461C8.95896 15.0093 6.56274 16.0105 4.79792 17.7841C3.03311 19.5578 2.04381 21.9589 2.047 24.461L2 109.613L20.923 90.691H87.152C89.6589 90.6836 92.0611 89.6845 93.8338 87.9118C95.6065 86.1391 96.6056 83.7369 96.613 81.23V24.461C96.6056 21.9541 95.6065 19.5519 93.8338 17.7792C92.0611 16.0065 89.6589 15.0074 87.152 15V15Z"
        stroke="#264D60"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.1541 0.121094H103.235C105.5 0.122154 107.672 1.02232 109.273 2.62384C110.875 4.22536 111.775 6.39722 111.776 8.66211V25.7431C111.775 28.0078 110.874 30.1794 109.273 31.7807C107.671 33.382 105.5 34.282 103.235 34.2831H86.1541C83.8893 34.2823 81.7174 33.3823 80.1158 31.7809C78.5143 30.1796 77.6141 28.0079 77.613 25.7431V8.66211C77.6141 6.39722 78.5143 4.22536 80.1158 2.62384C81.7173 1.02232 83.8892 0.122154 86.1541 0.121094Z"
        fill="#33A3FF"
      />
    </svg>
  );
};

export default LiveChat;
