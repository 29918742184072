import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Input from "../../components/Form/Input";
import TextArea from "../../components/Form/TextArea";
import Button from "../../components/Form/Button";
import Wallet from "../../components/Icons/Wallet";
import GradCap from "../../components/Icons/GradCap";
import Diagnostics from "../../components/Icons/Diagnostics";
import Clock from "../../components/Icons/Clock";
import Quick from "../../components/Icons/Quick";
import Save from "../../components/Icons/Save";
import ArrowRight from "../../components/Icons/ArrowRight";

import { checkValidity } from "../../shared/validations";

class Partnership extends Component {
  state = {
    reachUsForm: {
      fullName: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      email: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      message: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
    },
    formIsValid: false,
    tabItem: "Business",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnSwitchTab = (tabItem) => {
    this.setState({ tabItem });
  };

  handleInputOnChange = (
    event: Object,
    elementId: string,
    validations: Object
  ) => {
    const value = event.target.value;

    const updatedFormElement = {
      ...this.state.reachUsForm[elementId],
      value,
      valid: checkValidity(value, validations),
      messageClassName:
        !checkValidity(value, validations) && value !== ""
          ? "input__message--error"
          : "none",
    };

    const updatedForm = {
      ...this.state.reachUsForm,
      [elementId]: updatedFormElement,
    };

    let formIsValid = true;
    for (let elementId in updatedForm) {
      formIsValid = updatedForm[elementId].valid && formIsValid;
    }

    this.setState({
      reachUsForm: updatedForm,
      formIsValid: formIsValid,
    });
  };

  handleInputFocus = (name: string, updatedState: Object) => {
    const updatedNameObject = {
      ...this.state.reachUsForm[name],
      ...updatedState,
    };

    const updatedForm = {
      ...this.state.reachUsForm,
      [name]: updatedNameObject,
    };

    this.setState({
      reachUsForm: updatedForm,
    });
  };

  encode = (data: Object) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  handleOnSubmit = (e: Object) => {
    e.preventDefault();

    const form = e.target;
    const reachUsForm = this.state.reachUsForm;

    if (
      reachUsForm.fullName.valid &&
      reachUsForm.fullName.value !== "" &&
      reachUsForm.email.valid &&
      reachUsForm.email.value !== "" &&
      reachUsForm.message.valid &&
      reachUsForm.message.value !== ""
    ) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": form.getAttribute("name"),
          fullName: reachUsForm.fullName.value,
          email: reachUsForm.email.value,
          message: reachUsForm.message.value,
        }),
      })
        .then(() => window.alert("Your message has been sent!"))
        .catch((error) =>
          alert("An error occured while sending your message!")
        );
    } else {
      window.alert("Invalid Form Submission!");
    }
  };

  render() {
    const reachUsForm = this.state.reachUsForm;

    return (
      <div className="Partnership">
        <Helmet>
          <meta name="title" content=" Tinc Support | Partner with us" />
          <meta
            name="description"
            content="We care about your needs that is why we have solutions tailored to your budget and repair needs with benefits attached"
          />
          <meta
            property="og:title"
            content=" Tinc Support | Partner with us"
          />
          <meta
            property="og:url"
            content="https://support.tinc.com.ng/partnership"
          />
          <meta
            property="og:description"
            content="We care about your needs that is why we have solutions tailored to your budget and repair needs with benefits attached"
          />
          <link
            rel="canonical"
            href="https://support.tinc.com.ng/partnership"
          />
          <title>Tinc Support | Partner with us</title>
        </Helmet>
        <Header title="Partnership">
          <div className="Header__body__content">
            <h1>Partner With Us</h1>
            <p>
              We care about your needs and that is why we have solutions
              tailored specifically to your budget and repair needs with
              benefits attached
            </p>
          </div>
        </Header>

        <div className="Partnership__body">
          <h5>Business &amp; Education</h5>
          <br />
          <h2>Tinc Support for Business and Education Needs</h2>
          <p>
            Your business depends on countless electronic gadgets and other
            devices to keep operations running smoothly. When one thing breaks
            down, so does your productivity. You can trust us to keep those
            gadgets and devices running optimally. Your business is too
            important not to have a back-up plan in place.
          </p>
          <br />
          <br />

          <div className="Partnership__body__benefits">
            <h3>Benefits of Partnering with Tinc</h3>
            <br />
            <div className="Partnership__body__benefits__items">
              <div className="row">
                <div className="col-md-6">
                  <div className="Partnership__body__benefits__item">
                    <Wallet />
                    <div className="Partnership__body__benefits__item__content">
                      <h5>Bulk Discount</h5>
                      <p>
                        Have a lot of repairs but need to stick to a budget?
                        We’ll give you great service for your biggest jobs,
                        while saving money in the process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Partnership__body__benefits__item">
                    <GradCap />
                    <div className="Partnership__body__benefits__item__content">
                      <h5>Staff &amp; Student Discounts</h5>
                      <p>
                        Whether you’re working towards a paycheck or a diploma,
                        We’ve got your back. Let us do the hard work for you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Partnership__body__benefits__item">
                    <Diagnostics />
                    <div className="Partnership__body__benefits__item__content">
                      <h5>Free Diagnostics &amp; Estimates</h5>
                      <p>
                        Determining the issue and how much a repair will cost
                        should never cost you, and with us it won’t.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Partnership__body__benefits__item">
                    <Clock />
                    <div className="Partnership__body__benefits__item__content">
                      <h5>Quick Quality Care</h5>
                      <p>
                        Our repairs are fast but we never compromise quality. We
                        believe in our work and offer a 90-day warranty on all
                        repairs.Quick Quality Care
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Partnership__body__benefits__item">
                    <Quick />
                    <div className="Partnership__body__benefits__item__content">
                      <h5>Quick Turnover</h5>
                      <p>
                        We know you don’t have all day so we’ll return your
                        device as quickly as possible. Most of our repairs can
                        be done same day.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Partnership__body__benefits__item">
                    <Save />
                    <div className="Partnership__body__benefits__item__content">
                      <h5>Save Cost</h5>
                      <p>
                        Our services will cost less compared to replacing the
                        device. We want you to be confident that you are getting
                        the best price.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Partnership__body__tabs">
            <div className="Partnership__body__tabs__header">
              <div
                className={`Partnership__body__tabs__header__item ${
                  this.state.tabItem === "Business" &&
                  "Partnership__body__tabs__header__item--active"
                }`}
                onClick={() => this.handleOnSwitchTab("Business")}
              >
                Tinc Support For Business
              </div>

              <div
                className={`Partnership__body__tabs__header__item ${
                  this.state.tabItem === "School" &&
                  "Partnership__body__tabs__header__item--active"
                }`}
                onClick={() => this.handleOnSwitchTab("School")}
              >
                Tinc Support For Schools
              </div>
            </div>

            <div className="Partnership__body__tabs__body">
              {this.state.tabItem === "Business" && (
                <div className="Partnership__body__tabs__body__content">
                  <h3>Solutions for Small and Medium Size Businesses</h3>
                  <br />
                  <p>
                    As a business owner, IT devices play a vital part of your
                    success and profitability. Device downtime for you or your
                    employees can be devastating and finding a solution can be
                    both costly and time consuming. At Tinc, we specialize in
                    ensuring you have minimal down time when any of your devices
                    go down. Whether the devices are corporate-owned or
                    employee-owned, we can provide a holistic warranty/repair
                    solution. Our customized maintenance (extended warranty)
                    programs provide the following advantages:
                  </p>
                  <ul>
                    <li>Secure data protocol to ensure confidentiality</li>
                    <li>Convenient pick-up/delivery service options</li>
                    <li>
                      Flexible SLA to help meet your organizational Objectives
                    </li>
                    <li>
                      The industry’s most highly trained and professional
                      technicians
                    </li>
                    <li>Apple Certified technicians</li>
                  </ul>
                </div>
              )}

              {this.state.tabItem === "School" && (
                <div className="Partnership__body__tabs__body__content">
                  <h3>Solutions for Schools</h3>
                  <br />
                  <p>
                    Our professionally trained technicians are experts in tablet
                    and laptop repairs for schools. From Windows laptops and
                    iPads to MacBooks and Android tablets, we fix school-issued
                    and student-owned devices of all makes and models.Your
                    school district or educational establishment can count on
                    Tinc Support for Quick turnaround time, cost savings, and
                    up to 30 days warranty. We create a tailored plan that fits
                    both your budgetary and repair needs. Interested in learning
                    more about working with the most trusted technology repair
                    partner for educators? Complete the form below and a member
                    of the Tinc Support team will be in touch with you as soon
                    as possible!
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="Partnership__body__reach">
            <h3>Please Fill The Form To Reach Out To Us</h3>

            <div className="Track__body__card">
              <form
                name="Partnerships"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleOnSubmit}
              >
                <input type="hidden" name="form-name" value="Partnerships" />
                <Input
                  label="Your name"
                  attributes={{
                    type: "text",
                    name: "fullName",
                    placeholder: "Full Name",
                    required: true,
                    theme: "default",
                    value: reachUsForm.fullName.value,
                    onChange: (event) =>
                      this.handleInputOnChange(event, "fullName", {
                        required: true,
                        isFullName: true,
                      }),
                    onFocus: () =>
                      this.handleInputFocus("fullName", {
                        focused: true,
                      }),
                    onBlur: () =>
                      this.handleInputFocus("fullName", {
                        focused: false,
                      }),
                  }}
                  hasError={!reachUsForm.fullName.valid}
                  focused={reachUsForm.fullName.focused}
                  message={
                    !reachUsForm.fullName.valid &&
                    !reachUsForm.fullName.focused &&
                    reachUsForm.fullName.value !== ""
                      ? "Your full name is required and must contain your first and last names to be valid"
                      : ""
                  }
                  messageClassName={reachUsForm.fullName.messageClassName}
                />

                <Input
                  label="Your Email"
                  attributes={{
                    type: "email",
                    name: "email",
                    placeholder: "you@example.com",
                    required: true,
                    theme: "default",
                    value: reachUsForm.email.value,
                    onChange: (event) =>
                      this.handleInputOnChange(event, "email", {
                        required: true,
                        isEmail: true,
                      }),
                    onFocus: () =>
                      this.handleInputFocus("email", {
                        focused: true,
                      }),
                    onBlur: () =>
                      this.handleInputFocus("email", {
                        focused: false,
                      }),
                  }}
                  hasError={!reachUsForm.email.valid}
                  focused={reachUsForm.email.focused}
                  message={
                    !reachUsForm.email.valid &&
                    !reachUsForm.email.focused &&
                    reachUsForm.email.value !== ""
                      ? "Your email is required and must be valid"
                      : ""
                  }
                  messageClassName={reachUsForm.email.messageClassName}
                />

                <TextArea
                  label="Your Message"
                  attributes={{
                    type: "text",
                    name: "message",
                    placeholder: "Enter your message here",
                    required: true,
                    theme: "default",
                    value: reachUsForm.message.value,
                    onChange: (event) =>
                      this.handleInputOnChange(event, "message", {
                        required: true,
                      }),
                    onFocus: () =>
                      this.handleInputFocus("message", {
                        focused: true,
                      }),
                    onBlur: () =>
                      this.handleInputFocus("message", {
                        focused: false,
                      }),
                  }}
                  hasError={!reachUsForm.message.valid}
                  focused={reachUsForm.message.focused}
                  message={
                    !reachUsForm.message.valid &&
                    !reachUsForm.message.focused &&
                    reachUsForm.message.value !== ""
                      ? "Your message is required"
                      : ""
                  }
                  messageClassName={reachUsForm.message.messageClassName}
                />

                <div className="Track__body__card__button">
                  <Button disabled={!this.state.formIsValid}>
                    Submit <ArrowRight />
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Partnership;
