import React from "react";

const ArrowRight = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
      <path
        d="M26.001 50C39.2558 50 50.001 39.2548 50.001 26C50.001 12.7452 39.2558 2 26.001 2C12.7461 2 2.00098 12.7452 2.00098 26C2.00098 39.2548 12.7461 50 26.001 50Z"
        stroke="#091820"
        strokeWidth="3"
      />
      <path
        d="M23.5342 16.3662L32.4133 25.2454L23.5342 34.1245"
        stroke="#091820"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
