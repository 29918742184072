//@flow
import React, { type Node } from "react";

type MenuProps = {
  onClick?: Function,
};

export const menu = (props: MenuProps): Node => {
  return (
    <svg
      width="31"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      onClick={props.onClick}
    >
      <rect width="30.375" height="6.75" rx="3.375" fill="white" />
      <rect x="26.5781" width="3.79688" height="4.21875" fill="white" />
      <rect y="10.125" width="30.375" height="6.75" rx="3.375" fill="white" />
      <rect
        x="26.5781"
        y="10.125"
        width="3.79688"
        height="4.21875"
        fill="white"
      />
      <rect y="20.25" width="30.375" height="6.75" rx="3.375" fill="white" />
      <rect
        x="26.5781"
        y="20.25"
        width="3.79688"
        height="4.21875"
        fill="white"
      />
    </svg>
  );
};

export default menu;