import React from "react";

const SpeakerRepair = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0H90C97.9554 0.0037099 105.584 3.16561 111.209 8.79089C116.834 14.4162 119.996 22.0446 120 30V90C119.996 97.9554 116.834 105.584 111.209 111.209C105.584 116.834 97.9554 119.996 90 120H30C22.0446 119.996 14.4162 116.834 8.79089 111.209C3.16561 105.584 0.0037099 97.9554 0 90V30C0.0037099 22.0446 3.16561 14.4162 8.79089 8.79089C14.4162 3.16561 22.0446 0.0037099 30 0Z"
        fill="#DEC9E3"
      />
      <path
        d="M75 30H45C43.4102 30.0047 41.8868 30.6384 40.7626 31.7626C39.6384 32.8868 39.0047 34.4102 39 36V84C39.0058 85.5868 39.6408 87.1064 40.7656 88.2256C41.8904 89.3448 43.4132 89.9721 45 89.97L75 90C76.5898 89.9953 78.1132 89.3616 79.2374 88.2374C80.3616 87.1132 80.9953 85.5898 81 84V36C80.9953 34.4102 80.3616 32.8868 79.2374 31.7626C78.1132 30.6384 76.5898 30.0047 75 30ZM60 36C61.1867 36 62.3467 36.3519 63.3334 37.0112C64.3201 37.6705 65.0892 38.6075 65.5433 39.7039C65.9974 40.8003 66.1162 42.0067 65.8847 43.1705C65.6532 44.3344 65.0818 45.4035 64.2426 46.2426C63.4035 47.0818 62.3344 47.6532 61.1705 47.8847C60.0067 48.1162 58.8003 47.9974 57.7039 47.5433C56.6075 47.0892 55.6705 46.3201 55.0112 45.3334C54.3519 44.3467 54 43.1867 54 42C54.0047 40.4102 54.6384 38.8868 55.7626 37.7626C56.8868 36.6384 58.4102 36.0047 60 36ZM60 84C57.0333 84 54.1332 83.1203 51.6664 81.472C49.1997 79.8238 47.2771 77.4811 46.1418 74.7402C45.0065 71.9994 44.7094 68.9834 45.2882 66.0736C45.867 63.1639 47.2956 60.4912 49.3934 58.3934C51.4912 56.2956 54.1639 54.867 57.0736 54.2882C59.9834 53.7094 62.9994 54.0065 65.7402 55.1418C68.4811 56.2771 70.8238 58.1997 72.472 60.6664C74.1203 63.1332 75 66.0333 75 69C74.9987 72.9778 73.4179 76.7924 70.6051 79.6051C67.7924 82.4179 63.9778 83.9987 60 84ZM60 60C58.22 60 56.4799 60.5278 54.9999 61.5168C53.5198 62.5057 52.3663 63.9113 51.6851 65.5559C51.0039 67.2004 50.8257 69.01 51.1729 70.7558C51.5202 72.5016 52.3774 74.1053 53.636 75.364C54.8947 76.6226 56.4984 77.4798 58.2442 77.8271C59.99 78.1743 61.7996 77.9961 63.4442 77.3149C65.0887 76.6337 66.4943 75.4802 67.4832 74.0001C68.4722 72.5201 69 70.78 69 69C69.0016 67.8177 68.7699 66.6466 68.3181 65.554C67.8664 64.4613 67.2035 63.4686 66.3675 62.6325C65.5314 61.7965 64.5387 61.1336 63.446 60.6819C62.3534 60.2301 61.1823 59.9984 60 60Z"
        fill="black"
      />
    </svg>
  );
};

export default SpeakerRepair;
