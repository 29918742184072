//@flow
import React, { type Node } from "react";

type CustomerLeftProps = {
  onClick?: Function,
};

const CustomerLeft = (props: CustomerLeftProps): Node => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      onClick={props.onClick}
    >
      <path
        d="M63.1 72.1909L51.9277 61.0186L63.1 49.8463"
        stroke="#33A3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60 30C43.4315 30 30 43.4315 30 60C30 76.5685 43.4315 90 60 90C76.5685 90 90 76.5685 90 60C90 43.4315 76.5685 30 60 30Z"
        stroke="#33A3FF"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CustomerLeft;
