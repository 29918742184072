import React, { Component } from "react";
import { Link } from "react-router-dom";

import Input from "../Form/Input";
import Button from "../Form/Button";
import ArrowRight from "../Icons/ArrowRight";

import TincLogo from "../../assets/images/tinc-logo.png";
import FacebookIcon from "../../assets/images/facebook-icon.png";
import TwitterIcon from "../../assets/images/twitter-icon.png";
import InstagramIcon from "../../assets/images/instagram-icon.png";
import LinkedinIcon from "../../assets/images/linkedin-icon.png";
import HeartIcon from "../../assets/images/heart-icon.png";

import { checkValidity } from "../../shared/validations";

class Footer extends Component {
  state = {
    subscribeForm: {
      email: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
    },
    formIsValid: false,
  };

  handleInputOnChange = (
    event: Object,
    elementId: string,
    validations: Object
  ) => {
    const value = event.target.value;

    const updatedFormElement = {
      ...this.state.subscribeForm[elementId],
      value,
      valid: checkValidity(value, validations),
      messageClassName:
        !checkValidity(value, validations) && value !== ""
          ? "input__message--error"
          : "none",
    };

    const updatedForm = {
      ...this.state.subscribeForm,
      [elementId]: updatedFormElement,
    };

    let formIsValid = true;
    for (let elementId in updatedForm) {
      formIsValid = updatedForm[elementId].valid && formIsValid;
    }

    this.setState({
      subscribeForm: updatedForm,
      formIsValid: formIsValid,
    });
  };

  handleInputFocus = (name: string, updatedState: Object) => {
    const updatedNameObject = {
      ...this.state.subscribeForm[name],
      ...updatedState,
    };

    const updatedForm = {
      ...this.state.subscribeForm,
      [name]: updatedNameObject,
    };

    this.setState({
      subscribeForm: updatedForm,
    });
  };

  encode = (data: Object) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  handleOnSubscribe = (e: Object) => {
    e.preventDefault();

    const form = e.target;
    const subscribeForm = this.state.subscribeForm;

    if (subscribeForm.email.valid && subscribeForm.email.value !== "") {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": form.getAttribute("name"),
          email: subscribeForm.email.value,
        }),
      })
        .then(() => window.alert("You have successfully subscribed!"))
        .catch((error) => alert("An error occured while subscribing you!"));
    } else {
      window.alert("Invalid form submission");
    }
  };

  render() {
    const subscribeForm = this.state.subscribeForm;

    return (
      <div className="Footer">
        <div className="Footer__bg">
          <div className="Footer__content">
            <div className="Footer__subscribe">
              <h1>Subscribe To Our Newsletter</h1>
              <br />
              <div className="Footer__subscribe__content">
                <div className="Footer__subscribe__content__text">
                  Get the best advice and after sales support for your gadgets.
                </div>
                <div className="Footer__subscribe__content__form">
                  <form
                    name="Subscribers"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleOnSubscribe}
                  >
                    <input type="hidden" name="form-name" value="Subscribers" />
                    <Input
                      attributes={{
                        type: "email",
                        name: "email",
                        placeholder: "you@example.com",
                        required: true,
                        theme: "dark",
                        value: subscribeForm.email.value,
                        onChange: (event) =>
                          this.handleInputOnChange(event, "email", {
                            required: true,
                            isEmail: true,
                          }),
                        onFocus: () =>
                          this.handleInputFocus("email", {
                            focused: true,
                          }),
                        onBlur: () =>
                          this.handleInputFocus("email", {
                            focused: false,
                          }),
                      }}
                      hasError={!subscribeForm.email.valid}
                      focused={subscribeForm.email.focused}
                      message={
                        !subscribeForm.email.valid &&
                        !subscribeForm.email.focused &&
                        subscribeForm.email.value !== ""
                          ? "Your email is required and must be valid"
                          : ""
                      }
                      messageClassName={subscribeForm.email.messageClassName}
                    />
                    <div className="Footer__subscribe__content__form__button">
                      <Button disabled={!this.state.formIsValid}>
                        Subscribe <ArrowRight />
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <hr className="Footer__divider" />

            <div className="Footer__main">
              <div className="Footer__main__content">
                <img src={TincLogo} alt="Tinc Logo" />
                <p>
                  Tinc Support is a technology company that helps restore your
                  connection to the outside world. We ﬁx electronic devices from
                  the classic unexpected phone drop to the laptop that stops
                  working for ‘no’ reason. We’ve seen it all.
                </p>

                <div className="Footer__main__content__socials">
                  <a
                    href="https://www.linkedin.com/company/tinc-support/"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    <img src={LinkedinIcon} alt="Linkedin Social Icon" />
                  </a>

                  <a
                    href="https://twitter.com/tincsupport?"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    <img src={TwitterIcon} alt="Twitter Social Icon" />
                  </a>

                  <a
                    href="https://www.instagram.com/tincsupport"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    <img src={InstagramIcon} alt="Instagram Social Icon" />
                  </a>

                  <a
                    href="https://www.facebook.com/profile.php?id=100087837692947"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    <img src={FacebookIcon} alt="Facebook Social Icon" />
                  </a>
                </div>
              </div>

              <div className="Footer__main__links">
                <h2>Pages</h2>
                <br />
                <Link to="/">Home</Link>
                <Link to="/repair">Repairs</Link>
                <Link to="/services">Services</Link>
                <Link to="/partnership">Partnership</Link>
                <Link
                  to={{
                    pathname: "/support",
                    hash: "#faq",
                    state: { hash: "faq" },
                  }}
                >
                  FAQ's
                </Link>
              </div>

              <div className="Footer__main__links">
                <h2>Services</h2>
                <br />
                <Link to="/repair">Phone Repair</Link>
                <Link to="/repair">Tablet Repair</Link>
                <Link to="/repair">Mac &amp; PC Repair</Link>
                <Link to="/repair">Microsoldering</Link>
                <Link to="/repair">Smartwatch &amp; Assesories</Link>
                <Link to="/repair">Smartwatch &amp; Speaker Repair</Link>
              </div>

              <div className="Footer__main__contact">
                <h2>Contact</h2>
                <br />
                <span>
                  <b>Phone:</b>
                  <a href="tel:+2348096957186">&nbsp; +234 809 695 7186</a>,
                  <br />
                  <br />
                  <a href="tel:+2347040409881">+234 704 040 9881</a>
                </span>
                <span>
                  <b>Email:</b>
                  <a href="mailto:support@tinc.com.ng">
                    &nbsp;support@tinc.com.ng
                  </a>
                </span>
                <span>
                  <b>Find Us:</b>&nbsp;4 Francis Oremeji,
                  <br />
                  <br />
                  His Grace Plaza, Office 13,
                  <br />
                  <br />
                  Last Floor, Ikeja, Lagos
                  <br />
                  <br />
                  100001.
                </span>
                <span>
                  Mon - Wed: 9am - 5pm
                  <br /><br />
                  Thurs: 10am - 5pm
                  <br /><br />
                  Fri: 9am - 5pm
                </span>
              </div>
            </div>

            <div className="Footer__copy">
              Copyright {new Date().getFullYear()}. All Rights Reserved. With{" "}
              <img src={HeartIcon} alt="Love" /> From Tinc
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
