import React from "react";

const Warranty = () => {
  return (
    <svg
      width="104"
      height="110"
      viewBox="0 0 104 110"
      fill="none"
    >
      <path
        d="M43.6586 107.345C43.6586 107.345 84.2586 87.045 84.2586 56.589V21.06L43.6586 5.83301L3.05859 21.06V56.589C3.05859 87.043 43.6586 107.345 43.6586 107.345Z"
        stroke="black"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.501 54.75C71.9563 54.75 68.5188 54.0563 65.284 52.6881C62.1584 51.3661 59.3509 49.473 56.9394 47.0616C54.5279 44.6501 52.6349 41.8426 51.3129 38.717C49.9447 35.4822 49.251 32.0447 49.251 28.5C49.251 24.9553 49.9447 21.5178 51.3129 18.283C52.6349 15.1574 54.5279 12.3499 56.9394 9.93845C59.3509 7.52697 62.1584 5.63393 65.284 4.31193C68.5188 2.94373 71.9563 2.25 75.501 2.25C79.0457 2.25 82.4832 2.94373 85.718 4.31193C88.8435 5.63393 91.651 7.52697 94.0625 9.93845C96.474 12.3499 98.367 15.1574 99.689 18.283C101.057 21.5178 101.751 24.9553 101.751 28.5C101.751 32.0447 101.057 35.4822 99.689 38.717C98.367 41.8426 96.474 44.6501 94.0625 47.0616C91.651 49.473 88.8435 51.3661 85.718 52.6881C82.4832 54.0563 79.0457 54.75 75.501 54.75Z"
        fill="#33A3FF"
      />
      <path
        d="M75.501 4.5C72.259 4.5 69.1164 5.13393 66.1605 6.38419C63.3031 7.59275 60.736 9.32387 58.5304 11.5294C56.3248 13.735 54.5937 16.3021 53.3852 19.1595C52.1349 22.1154 51.501 25.258 51.501 28.5C51.501 31.742 52.1349 34.8846 53.3852 37.8405C54.5937 40.6979 56.3248 43.265 58.5304 45.4706C60.736 47.6761 63.3031 49.4072 66.1605 50.6158C69.1164 51.8661 72.259 52.5 75.501 52.5C78.7429 52.5 81.8855 51.8661 84.8415 50.6158C87.6988 49.4072 90.266 47.6761 92.4715 45.4706C94.6771 43.265 96.4082 40.6979 97.6168 37.8405C98.867 34.8846 99.501 31.742 99.501 28.5C99.501 25.258 98.867 22.1154 97.6168 19.1595C96.4082 16.3021 94.6771 13.735 92.4715 11.5294C90.266 9.32387 87.6988 7.59275 84.8415 6.38419C81.8855 5.13393 78.7429 4.5 75.501 4.5ZM75.501 0C91.2411 0 104.001 12.7599 104.001 28.5C104.001 44.2401 91.2411 57 75.501 57C59.7609 57 47.001 44.2401 47.001 28.5C47.001 12.7599 59.7609 0 75.501 0Z"
        fill="#33A3FF"
      />
      <path
        d="M89.8872 18.749L71.1382 37.498L62.6162 28.976"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Warranty;
