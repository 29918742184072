//@flow
import React, { type Node, useState } from "react";
import { NavLink } from "react-router-dom";

import MenuIcon from "../Icons/Menu";

import TincLogo from "../../assets/images/tinc-logo.png";
import AppleLogo from "../../assets/images/apple-logo.png";
import SamsungLogo from "../../assets/images/samsung-logo.png";
import DellLogo from "../../assets/images/dell-logo.png";
import HpLogo from "../../assets/images/hp-logo.png";
import HuaweiLogo from "../../assets/images/huawei-logo.png";
import LgLogo from "../../assets/images/lg-logo.png";
import NokiaLogo from "../../assets/images/nokia-logo.png";
import OneplusLogo from "../../assets/images/oneplus-logo.png";

type HeaderProps = {
  title?: String,
  isBottom?: Boolean,
  children: Node,
};

const Header = (props: HeaderProps): Node => {
  const [isMenuActive, toggleMenu] = useState(false);
  return (
    <div className="Header">
      <div className="Header__bg">
        <div className="Header__bg__text">{props.title && props.title}</div>

        <div
          className={` ${
            isMenuActive ? "Header__top Header__top--dark" : "Header__top"
          }`}
        >
          <div className="Header__menu">
            <div className="Header__menu__inner">
              <div className="Header__menu__icon">
                <MenuIcon onClick={() => toggleMenu(!isMenuActive)} />
              </div>

              <div className="Header__menu__logo">
                <NavLink to="/">
                  <img src={TincLogo} alt="Tinc Logo" />
                </NavLink>
              </div>
              <div className="Header__menu__links">
                <NavLink
                  to="/repair"
                  className="Header__menu__link"
                  activeClassName="Header__menu__link--active"
                >
                  Repair
                </NavLink>
                <NavLink
                  to="/track"
                  className="Header__menu__link"
                  activeClassName="Header__menu__link--active"
                >
                  Track Repair
                </NavLink>
                <NavLink
                  to="/partnership"
                  className="Header__menu__link"
                  activeClassName="Header__menu__link--active"
                >
                  Partnership
                </NavLink>
                <NavLink
                  to="/about"
                  className="Header__menu__link"
                  activeClassName="Header__menu__link--active"
                >
                  About
                </NavLink>
                <div className="Header__menu__support">
                  <NavLink
                    to="/support"
                    className="Header__menu__link"
                    activeClassName="Header__menu__link--active"
                  >
                    Support <i className="fas fa-caret-down"></i>
                  </NavLink>

                  <div className="Header__menu__support__subMenu">
                    <NavLink
                      to={{
                        pathname: "/support",
                        hash: "#faq",
                        state: { hash: "faq" },
                      }}
                      className="Header__menu__support__link"
                      activeClassName="Header__menu__support__link--active"
                    >
                      FAQ's
                    </NavLink>

                    <NavLink
                      to={{
                        pathname: "/support",
                        hash: "#contact",
                        state: { hash: "contact" },
                      }}
                      className="Header__menu__support__link"
                      activeClassName="Header__menu__support__link--active"
                    >
                      Contact Us
                    </NavLink>

                    <NavLink
                      to={{
                        pathname: "/support",
                        hash: "#policy",
                        state: { hash: "policy" },
                      }}
                      className="Header__menu__support__link"
                      activeClassName="Header__menu__support__link--active"
                    >
                      Terms and Conditions
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="Header__apple">
                <div className="Header__apple__logo">
                  <img src={AppleLogo} alt="Apple Logo" />
                </div>
                <div className="Header__apple__text">
                  Apple
                  <br />
                  Certified
                  <br />
                  Technicians
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMenuActive && (
          <div className="Header__smallMenu">
            <div className="Header__smallMenu__links">
              <NavLink
                to="/repair"
                className="Header__smallMenu__link"
                activeClassName="Header__smallMenu__link--active"
              >
                Repair
              </NavLink>
              <NavLink
                to="/track"
                className="Header__smallMenu__link"
                activeClassName="Header__smallMenu__link--active"
              >
                Track Repair
              </NavLink>
              <NavLink
                to="/partnership"
                className="Header__smallMenu__link"
                activeClassName="Header__smallMenu__link--active"
              >
                Partnership
              </NavLink>
              <NavLink
                to="/about"
                className="Header__smallMenu__link"
                activeClassName="Header__smallMenu__link--active"
              >
                About
              </NavLink>
              <NavLink
                to="/support"
                className="Header__smallMenu__link"
                activeClassName="Header__smallMenu__link--active"
              >
                Support
              </NavLink>
            </div>
          </div>
        )}

        <div className="Header__body">{props.children}</div>
      </div>

      {props.isBottom && (
        <div className="Header__bottom">
          <div className="Header__bottom__inner">
            <img src={AppleLogo} alt="Apple Logo" />
            <img src={SamsungLogo} alt="Samsung Logo" />
            <img src={HuaweiLogo} alt="Huawei Logo" />
            <img src={LgLogo} alt="Lg Logo" />
            <div className="Header__bottom__inner--remove">
              <img src={NokiaLogo} alt="Nokia Logo" />
              <img src={OneplusLogo} alt="Oneplus Logo" />
              <img src={HpLogo} alt="Hp Logo" />
              <img src={DellLogo} alt="Dell Logo" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Header.defaultProps = {
  isBottom: true,
};

export default Header;
