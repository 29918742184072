import { Route, Switch, Redirect, withRouter } from "react-router-dom";

//pages
import Home from "./containers/Home";
import Repair from "./containers/Repair";
import About from "./containers/About";
import Track from "./containers/Track";
import Support from "./containers/Support";
import Partnership from "./containers/Partnership";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/repair" component={Repair} />
        <Route path="/about" component={About} />
        <Route path="/track" component={Track} />
        <Route path="/support" component={Support} />
        <Route path="/partnership" component={Partnership} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default withRouter(App);
