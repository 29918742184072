import React from "react";

const OtherRepairs = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0H90C97.9554 0.0037099 105.584 3.16561 111.209 8.79089C116.834 14.4162 119.996 22.0446 120 30V90C119.996 97.9554 116.834 105.584 111.209 111.209C105.584 116.834 97.9554 119.996 90 120H30C22.0446 119.996 14.4162 116.834 8.79089 111.209C3.16561 105.584 0.0037099 97.9554 0 90V30C0.0037099 22.0446 3.16561 14.4162 8.79089 8.79089C14.4162 3.16561 22.0446 0.0037099 30 0Z"
        fill="#96D0FF"
      />
      <path
        d="M60 90C76.5685 90 90 76.5685 90 60C90 43.4315 76.5685 30 60 30C43.4315 30 30 43.4315 30 60C30 76.5685 43.4315 90 60 90Z"
        fill="#33A3FF"
      />
      <path d="M44.9668 60.4673H75.3438" stroke="white" strokeWidth="5" />
      <path d="M60.1562 45.2783V75.6553" stroke="white" strokeWidth="5" />
    </svg>
  );
};

export default OtherRepairs;
