import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TrackArrow from "../../components/Icons/TrackArrow";
/*
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import ArrowRight from "../../components/Icons/ArrowRight";
*/

import { checkValidity } from "../../shared/validations";

class Track extends Component {
  state = {
    trackForm: {
      ticketId: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      lastName: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
    },
    formIsValid: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleInputOnChange = (
    event: Object,
    elementId: string,
    validations: Object
  ) => {
    const value = event.target.value;

    const updatedFormElement = {
      ...this.state.trackForm[elementId],
      value,
      valid: checkValidity(value, validations),
      messageClassName:
        !checkValidity(value, validations) && value !== ""
          ? "input__message--error"
          : "none",
    };

    const updatedForm = {
      ...this.state.trackForm,
      [elementId]: updatedFormElement,
    };

    let formIsValid = true;
    for (let elementId in updatedForm) {
      formIsValid = updatedForm[elementId].valid && formIsValid;
    }

    this.setState({
      trackForm: updatedForm,
      formIsValid: formIsValid,
    });
  };

  handleInputFocus = (name: string, updatedState: Object) => {
    const updatedNameObject = {
      ...this.state.trackForm[name],
      ...updatedState,
    };

    const updatedForm = {
      ...this.state.trackForm,
      [name]: updatedNameObject,
    };

    this.setState({
      trackForm: updatedForm,
    });
  };

  handleOnSubmit = (e: Object) => {
    e.preventDefault();
    const trackForm = this.state.trackForm;

    if (
      trackForm.ticketId.valid &&
      trackForm.ticketId.value !== "" &&
      trackForm.lastName.valid &&
      trackForm.lastName.value !== ""
    ) {
      console.log("submitted!");
    } else {
      window.alert("Invalid Form Submission!");
    }
  };

  render() {
    //const trackForm = this.state.trackForm;

    return (
      <div className="Track">
        <Helmet>
          <meta name="title" content=" Tinc Support | Repair tracker" />
          <meta
            name="description"
            content="If you have already sent in your device, you can check the current status of your repair"
          />
          <meta property="og:title" content=" Tinc Support | Repair tracker" />
          <meta
            property="og:url"
            content="https://support.tinc.com.ng/track"
          />
          <meta
            property="og:description"
            content="If you have already sent in your device, you can check the current status of your repair"
          />
          <link rel="canonical" href="https://support.tinc.com.ng/track" />
          <title>Tinc Support | Repair tracker</title>
        </Helmet>
        <Header title="Tracking">
          <div className="Track__header">
            <h1>Track Your Device</h1>
            <br />
            <br />
            <div className="Track__header__process">
              <div className="Track__header__process__item">
                <div className="Track__header__process__item__box"></div>
                <br />
                <div className="Track__header__process__item__circle">
                  <div className="Track__header__process__item__circle--inner"></div>
                </div>
                <br />
                <div className="Track__header__process__item__text">
                  Your device has <br /> been registered
                </div>
              </div>
              <TrackArrow />
              <div className="Track__header__process__item">
                <div className="Track__header__process__item__box"></div>
                <br />
                <div className="Track__header__process__item__circle">
                  <div className="Track__header__process__item__circle--inner"></div>
                </div>
                <br />
                <div className="Track__header__process__item__text">
                  Your device is <br /> under diagnostics
                </div>
              </div>
              <TrackArrow />
              <div className="Track__header__process__item Track__header__process__item--active">
                <div className="Track__header__process__item__box"></div>
                <br />
                <div className="Track__header__process__item__circle">
                  <div className="Track__header__process__item__circle--inner"></div>
                </div>
                <br />
                <div className="Track__header__process__item__text">
                  Your device is <br /> being repaired
                </div>
              </div>
              <TrackArrow />
              <div className="Track__header__process__item">
                <div className="Track__header__process__item__box"></div>
                <br />
                <div className="Track__header__process__item__circle">
                  <div className="Track__header__process__item__circle--inner"></div>
                </div>
                <br />
                <div className="Track__header__process__item__text">
                  Your device <br /> has been fixed
                </div>
              </div>
            </div>
          </div>
        </Header>
        <div class="iframe-track">
          <iframe
            title="Track iframe"
            src={
              "https://boddssupport.repairdesk.co/widget.php?token=5cf0cb556b0261559284565&r=site/orderlookup"
            }
            style={{
              scrolling: "no",
               marginTop: 120,
            }}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Track;
