import React from "react";

const TabletRepair = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0H90C97.9554 0.0037099 105.584 3.16561 111.209 8.79089C116.834 14.4162 119.996 22.0446 120 30V90C119.996 97.9554 116.834 105.584 111.209 111.209C105.584 116.834 97.9554 119.996 90 120H30C22.0446 119.996 14.4162 116.834 8.79089 111.209C3.16561 105.584 0.0037099 97.9554 0 90V30C0.0037099 22.0446 3.16561 14.4162 8.79089 8.79089C14.4162 3.16561 22.0446 0.0037099 30 0Z"
        fill="#F5C5C5"
      />
      <path
        d="M80.6344 25H39.5674C37.6228 25.0008 35.7581 25.7736 34.383 27.1487C33.008 28.5237 32.2352 30.3884 32.2344 32.333V88.065C32.2346 90.0099 33.0072 91.8752 34.3823 93.2506C35.7574 94.6261 37.6224 95.3992 39.5674 95.4H80.6344C82.579 95.3992 84.4437 94.6264 85.8187 93.2513C87.1937 91.8763 87.9666 90.0116 87.9674 88.067V32.333C87.9666 30.3884 87.1937 28.5237 85.8187 27.1487C84.4437 25.7736 82.579 25.0008 80.6344 25V25ZM60.1004 92.465C59.2301 92.465 58.3794 92.2069 57.6559 91.7235C56.9323 91.24 56.3683 90.5528 56.0353 89.7488C55.7023 88.9448 55.6151 88.0601 55.7849 87.2066C55.9547 86.3531 56.3738 85.5691 56.9891 84.9537C57.6045 84.3384 58.3885 83.9193 59.242 83.7495C60.0955 83.5798 60.9802 83.6669 61.7842 83.9999C62.5882 84.333 63.2754 84.8969 63.7588 85.6205C64.2423 86.3441 64.5004 87.1948 64.5004 88.065C64.5012 88.643 64.3879 89.2156 64.1671 89.7497C63.9462 90.2839 63.6221 90.7693 63.2134 91.178C62.8047 91.5868 62.3193 91.9108 61.7851 92.1317C61.2509 92.3525 60.6784 92.4658 60.1004 92.465ZM82.1004 80.732H38.1004V33.8H82.1004V80.732Z"
        fill="#CC3232"
      />
    </svg>
  );
};

export default TabletRepair;
