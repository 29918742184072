import React from "react";

const TrackArrow = () => {
  return (
    <svg className="TrackArrow" width="165" height="12" viewBox="0 0 165 12" fill="none">
      <g opacity="0.6">
        <path
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.194 0V12L165 5.29999L160.194 0Z"
          fill="#C8C8C8"
        />
        <path
          opacity="0.6"
          d="M0 5.99902H160.194"
          stroke="white"
          strokeWidth="0.8"
        />
      </g>
    </svg>
  );
};

export default TrackArrow;
